import CloseButton from 'components/button/CloseButton/CloseButton';
import DeleteButton from 'components/button/DeleteButton/DeleteButton';
import EditButton from 'components/button/EditButton/EditButton';
import MainButton from 'components/button/MainButton/MainButton';
import FileInput from 'components/input/FileInput/FileInput';
import SelectInput from 'components/input/SelectInput/SelectInput';
import TextAreaInput from 'components/input/TextAreaInput/TextAreaInput';
import TextInput from 'components/input/TextInput/TextInput';
import { PageData } from 'data/interfaces/PageData';
import { Section } from 'data/interfaces/Section';
import { SelectInputOptions } from 'data/interfaces/SeletcDataOptions';
import { backendUrl } from 'data/routes/backendRoutes';
import { FormEvent, useRef, useState } from 'react';
import PageDataFormDetailList from '../PageDataFormDetailList/PageDataFormDetailList';
import classes from './PageDataForm.module.scss';

interface Props extends PageData {
  index: number;
  isEditable: boolean;
  sectionData: Array<Section>;
  formTitle?: string;
  closeWindowHandler?: () => void;
  dispatch: (
    index: number,
    id: string,
    title: string,
    sectionId: string,
    text: string,
    list?: Array<string>,
    googleMaps?: string,
    image?: File
  ) => void;
  deleteHandler?: (id: string) => void;
}

const PageDataForm = ({
  id,
  image_src,
  section_id,
  text,
  title,
  index,
  isEditable,
  sectionData,
  formTitle,
  list,
  dispatch,
  deleteHandler,
  closeWindowHandler,
  map_url
}: Props) => {
  const titleRef = useRef<HTMLInputElement>(null);
  const textRef = useRef<HTMLTextAreaElement>(null);
  const sectionRef = useRef<HTMLSelectElement>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);
  const googleMapsRef = useRef<HTMLInputElement>(null);

  const [isReadonly, setIsReadonly] = useState<boolean>(!isEditable ? false : true);
  const [newList, setNewList] = useState<Array<string>>(list !== null ? list : []);
  
  const toggleReadonly = () => {
    setIsReadonly(!isReadonly);
  }

  const handleListChange = (updatedList: Array<string>) => {
    setNewList(updatedList);
  }

  const submitFormHandler = (event: FormEvent) => {
    event.preventDefault();

    if (!imageInputRef.current!.files) {
      return;
    }

    const titleValue = titleRef.current!.value;
    const sectionValue = sectionRef.current!.value;
    const textValue = textRef.current!.value;
    const imageValue = imageInputRef.current!.files[0];
    const googleMapsValue = googleMapsRef.current!.value;

    if (isEditable) {
      toggleReadonly();
    }

    if (!imageValue) {
      dispatch(index, id, sectionValue, titleValue, textValue, newList, googleMapsValue);
      return;
    }
    
    
    dispatch(index, id, sectionValue, titleValue, textValue, newList, googleMapsValue, imageValue);
  }

  const selectOptions = [] as Array<SelectInputOptions>;
  sectionData.forEach(((section, index) => {
    if (index < 5 && index !== 2) {
      const sectionOption = {
        title: section.title,
        value: section.id
      } as SelectInputOptions

      selectOptions.push(sectionOption);
    }
  }))

  const formClass = !isReadonly
    ? `${classes['page-data-form']} ${classes['page-data-form--edit']}`
    : `${classes['page-data-form']}`;

  return (
    <form
      className={formClass}
      onSubmit={submitFormHandler}
    >
      <div className={classes['page-data-form__toolbar']}>
        {deleteHandler && !isReadonly &&
          <DeleteButton
            onClick={deleteHandler.bind(this, id)}
          />}
        
        {isEditable &&
          <EditButton
            isReadonly={isReadonly}
            onClick={toggleReadonly}
          />}
        
        {!isEditable && <CloseButton
          onClick={closeWindowHandler ? closeWindowHandler : () => {}}
        />}
      </div>

      {formTitle && <h1>{formTitle}</h1>}

      <TextInput
        ref={titleRef}
        title={'Title'}
        value={title}
        isReadonly={isReadonly}
      />

      <TextAreaInput
        ref={textRef}
        title='Text'
        value={text}
        isReadonly={isReadonly}
      />

      <PageDataFormDetailList
        list={list}
        isReadonly={isReadonly}
        handleListChange={handleListChange}
      />

      <SelectInput
        ref={sectionRef}
        options={selectOptions}
        title='Section'
        selectedValue={section_id}
      />

      {+section_id === 2 && <TextInput
        ref={googleMapsRef}
        title='Google maps'
        isReadonly={isReadonly}
        value={map_url === null ? '' : map_url}
      />}

      {image_src && <div className={classes['page-data-form__image']}>
        <h1>Image:</h1>
        <img src={`${backendUrl}/${image_src}`} alt={image_src} />
      </div>}

      {!isReadonly && <>
        <FileInput
          ref={imageInputRef}
          title={'Select Image'}
          isRequired={false}
          acceptedFile='image'
        />
        <MainButton
          title={isEditable ? 'EDIT' : 'CREATE'}
          isSubmit={true}
        />
      </>}
    </form>
  )
}

export default PageDataForm