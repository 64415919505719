import PageTitleElement from 'components/elements/PageTitleElement/PageTitleElement';
import { Section } from 'data/interfaces/Section';
import PageLayout from 'layouts/PageLayout/PageLayout';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import classes from './Index.module.scss';
import { useEffect } from 'react';
import { getPageData } from 'store/pageData/actions/page-data-get-action';
import PageDataMenu from 'components/elements/PageDataMenu/PageDataMenu';
import SpinnerFullscreen from 'components/spinner/SpinnerFullscreen/SpinnerFullscreen';
import PageDataElement from 'components/elements/PageDataElement/PageDataElement';
import ScrollTopButton from 'components/button/ScrollTopButton/ScrollTopButton';
import Slider from 'components/Slider/Slider';
import { getSlider } from 'store/slider/actions/get-slider-action';

const FahrradPage = () => {
  const dispatch = useDispatch() as Dispatch<any>;

  const data = useSelector((state: RootState) => state.section.data);
  const fahrradData = data.length > 0 ? data[0] : { id: '', image_src: '', route: '', title: '' } as Section;

  const { data: pageData, loading } = useSelector((state: RootState) => state.pageData);

  const { loading: sliderLoading, data: sliderData } = useSelector((state: RootState) => state.slider);

  useEffect(() => {
    dispatch(getSlider('1'));
  }, [dispatch])
  

  useEffect(() => {
    dispatch(getPageData('1'));
  }, [dispatch, fahrradData.id])

  
  const renderPageData = pageData.map(data => {
    return <PageDataElement
      key={data.id}
      image={data.image_src}
      title={data.title}
      text={data.text}
      list={data.list}
      isHover={true}
    />
  })

  return (
    <PageLayout>
      <section className={classes['fahrrad-page']}>
        <PageTitleElement
          image={ fahrradData.image_src }
          title={ fahrradData.title }
        />
        {loading && <SpinnerFullscreen isLoading={loading} />}
        {!loading && <>
          <PageDataMenu pageData={pageData} />
          {renderPageData}
        </>}

        {!sliderLoading && sliderData.length > 0 && sliderData[0].slides.length > 0 && <Slider
          slides={sliderData[0].slides}
          isBorderRadious={false}
        />}

        <ScrollTopButton />
      </section>
    </PageLayout>
  )
}

export default FahrradPage