import { createSlice } from "@reduxjs/toolkit";
import { SLIDER_FAIL, SLIDER_REQUEST, SLIDER_SUCCESS } from "data/constants/store/slider";
import { Slider } from "data/interfaces/Slider";

const sliderSlice = createSlice({
  name: 'slider',
  initialState: {
    loading: false,
    error: '',
    data: [] as Array<Slider>
  },
  reducers: {
    get(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case SLIDER_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case SLIDER_SUCCESS:
          state.loading = false;
          state.error = '';
          state.data = payload;
          break;
        case SLIDER_FAIL:
          state.loading = false;
          state.error = payload;
          break;
      }
    }
  }
})

export const sliderActions = sliderSlice.actions;
export default sliderSlice;