import { Dispatch } from "@reduxjs/toolkit";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { clientSetScroll } from "store/client/actions/set-scroll-action";

const useDetectScroll = () => {
  const dispatch = useDispatch() as Dispatch<any>;

  const setScroll = useCallback(() => {
    dispatch(clientSetScroll(window.scrollY))
  }, [dispatch])

  useEffect(() => {
    window.addEventListener('scroll', setScroll);
    return () => {
      window.removeEventListener('scroll', setScroll);
    }
  }, [setScroll])
}

export default useDetectScroll;