import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { MESSAGE_FAIL, MESSAGE_REQUEST, MESSAGE_SUCCESS } from "data/constants/store/message";
import { BACKEND_MESSAGE_ROUTE } from "data/routes/backendRoutes";
import { messageActions } from "../messages-slice";

export const getMessage = () => async (dispatch: Dispatch) => {
  dispatch(messageActions.get({ type: MESSAGE_REQUEST }));

  const getMessageRequest = async () => {
    return await axios.get(BACKEND_MESSAGE_ROUTE, { withCredentials: true });
  }

  try {
    const {data} = await getMessageRequest();

    dispatch(messageActions.get({
      type: MESSAGE_SUCCESS,
      payload: data
    }));
  } catch (error: any) {
    dispatch(messageActions.get({
      type: MESSAGE_FAIL,
      payload: error.response.data.message
    }))
  }
}