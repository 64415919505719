import AdminHeader from 'components/header/AdminHeader/AdminHeader';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import classes from './Index.module.scss';
import { RootState } from 'store';
import { useEffect, useState } from 'react';
import SpinnerFullscreen from 'components/spinner/SpinnerFullscreen/SpinnerFullscreen';
import { getSlider } from 'store/slider/actions/get-slider-action';
import CreateSlide from './CreateSlide/CreateSlide';
import { getSections } from 'store/section/actions/section-get-action';
import { SelectInputOptions } from 'data/interfaces/SeletcDataOptions';
import SelectInput from 'components/input/SelectInput/SelectInput';
import SlideForm from './SlideForm/SlideForm';
import { editSlide } from 'store/slide/actions/slide-edit-action';
import { deleteSlide } from 'store/slide/actions/slide-delete-actions';


const Slider = () => {
  const dispatch = useDispatch() as Dispatch<any>;

  const { loading, data } = useSelector((state: RootState) => state.slider);
  const { data: sectionData, loading: sectionLoading } = useSelector((state: RootState) => state.section);

  const [selectedSection, setSelectedSection] = useState<string>('');

  useEffect(() => {
    if (sectionData.length <= 0) {
      dispatch(getSections());
    }
  }, [dispatch, sectionData])

  useEffect(() => {
    if (selectedSection) {
      dispatch(getSlider(selectedSection));
      return;
    }
    dispatch(getSlider());

  }, [dispatch, selectedSection])

  const resetSection = () => {
    setSelectedSection('0');
  }

  const editSlideHandler = (
    id: string,
    title: string,
    description: string,
    section_id: string,
    image_src: File,
    index: number) => {
    dispatch(editSlide(index, id, section_id, title, description, image_src));
    resetSection();
  }

  const deleteSlideHandler = (id: string, index: number) => {
    dispatch(deleteSlide(id, index));
    resetSection();
  }

  const onSelectChangeHandler = (value: string) => { 
    setSelectedSection(value);
  }

  const selectOptions = [] as Array<SelectInputOptions>;
  sectionData.forEach(((section, index) => {
    if (index < 5 && index !== 2) {
      const sectionOption = {
        title: section.title,
        value: section.id
      } as SelectInputOptions

      selectOptions.push(sectionOption);
    }
  }))

  selectOptions.unshift({ title: 'All', value: '' });

  let renderData: Array<any> = [];

  data.length > 0 && data.forEach(slider => {
    slider.slides.forEach((slide, index) => {
      renderData.push(<SlideForm
        key={slide.id}
        description={slide.description}
        id={slide.id}
        image_src={slide.image_src}
        index={index}
        isEditable={true}
        section_id={slide.section_id}
        title={slide.title}
        sectionData={sectionData}
        dispatch={editSlideHandler}
        deleteHandler={deleteSlideHandler}
      />)
    })
  })

  return (
    <AdminLayout>
      <AdminHeader />
      <section className={classes['slider-page']}>
        {loading && <SpinnerFullscreen isLoading={loading} />}
        <CreateSlide
          sectionData={sectionData}
          resetSection={resetSection}
        />
        {!sectionLoading && <div className={classes['slider-page__select']}>
            <SelectInput
              options={selectOptions}
              title='Show by Section'
              onChange={onSelectChangeHandler}
              selectedValue={selectedSection}
            />
          </div>}
        <div className={classes['slider-page__data']}>{renderData}</div>
      </section>  
    </AdminLayout>
  )
}

export default Slider