import { CLIENT_ADMIN_ROUTE } from "data/routes/clientRoutes";
import AdminLayout from "layouts/AdminLayout/AdminLayout";
import LoginForm from "pages/authentication/Login/components/LoginForm/LoginForm";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "store";
import classes from "./index.module.scss";

const Login = () => {
  const navigate = useNavigate();
  const { loading, isAuthorized } = useSelector((state: RootState) => state.authentication);

  useEffect(() => {
    if (isAuthorized && !loading) {
      navigate(CLIENT_ADMIN_ROUTE);
    }
  }, [navigate, isAuthorized, loading])
  

  return (
    <AdminLayout>
      <section className={classes['login-page']}>
        <LoginForm />
      </section>  
    </AdminLayout>
   
  )
}

export default Login;