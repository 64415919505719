import useAssignRefs from 'hooks/useAssignRefs';
import { ChangeEvent, forwardRef, useId, useRef, useState } from 'react';
import InputWrapper from '../InputWrapper/InputWrapper';

type Props = {
  title: string;
  isRequired?: boolean;
  value?: string;
  isReadonly?: boolean;
}

const TextAreaInput = forwardRef<HTMLTextAreaElement, Props>(({
  title,
  isRequired = false,
  value = '',
  isReadonly
}, ref) => {

  const inputLocalRef = useRef<HTMLTextAreaElement>(null);
  const inputRef = useAssignRefs(inputLocalRef, ref);

  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(value);

  const focusInput = () => {
    inputLocalRef.current?.focus();
    setFocus();
  }

  const setFocus = () => {
    setIsFocus(true);
  }

  const setBlur = () => {
    setIsFocus(false);
  }

  const inputValueHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
  }

  const inputId = useId();
  return (
    <InputWrapper
      focusInput={focusInput}
      isInputFocus={isFocus}
      title={title}
      inputId={inputId}
      isValid={true}
      height={10}
    >
      <textarea
        ref={inputRef}
        id={inputId}
        autoCapitalize='none'
        autoCorrect='off'
        spellCheck='false'
        onBlur={setBlur}
        required={isRequired}
        readOnly={isReadonly}
        value={inputValue}
        onChange={inputValueHandler}
      />
    </InputWrapper>
  )
})

export default TextAreaInput