import { createSlice } from "@reduxjs/toolkit";
import { FERRY_FAIL, FERRY_REQUEST, FERRY_SUCCESS } from "data/constants/store/Ferry";
import { Ferry } from "data/interfaces/Ferry";

const ferrySlice = createSlice({
  name: 'ferry',
  initialState: {
    loading: false, 
    error: '',
    data: [] as Array<Ferry>
  },
  reducers: {
    get(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case FERRY_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case FERRY_SUCCESS:
          state.loading = false;
          state.error = '';
          state.data = payload;
          break;
        case FERRY_FAIL:
          state.loading = false;
          state.error = payload;
          break;
      }
    },
    create(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case FERRY_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case FERRY_SUCCESS:
          state.loading = false;
          state.error = '';
          // state.data.push(payload);
          state.data = payload;
          break;
        case FERRY_FAIL:
          state.loading = false;
          state.error = payload;
          break;
      }
    },
    edit(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case FERRY_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case FERRY_SUCCESS:
          state.loading = false;
          state.error = '';
          // state.data[payload.index] = payload.data;
          state.data = payload;
          break;
        case FERRY_FAIL:
          state.loading = false;
          state.error = payload;
          break;
      }
    },
    delete(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case FERRY_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case FERRY_SUCCESS:
          state.loading = false;
          state.error = '';
          state.data.splice(payload, 1);
          break;
        case FERRY_FAIL:
          state.loading = false;
          state.error = payload;
          break;
      }
    }
  }
})


export const ferryActions = ferrySlice.actions;
export default ferrySlice;