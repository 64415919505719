import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { EDIT_USER_FAIL, EDIT_USER_REQUEST, EDIT_USER_SUCCESS } from "data/constants/store/editUser";
import { BACKEND_LOGIN_ROUTE } from "data/routes/backendRoutes";
import { authenticationActions } from "../authentication-slice";

export const editUser = (
  currentPassword: string,
  newPassword: string
) => async (dispatch: Dispatch) => {
  dispatch(authenticationActions.edit({ type: EDIT_USER_REQUEST }));

  const data = {
    current_password: currentPassword,
    new_password: newPassword,
    method: 'PATCH'
  }
  console.log(data);
  
  const editUserRequest = async () => {
    return await axios.post(BACKEND_LOGIN_ROUTE, data, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });
  }

  try {
    await editUserRequest();
    
    dispatch(authenticationActions.edit({
      type: EDIT_USER_SUCCESS,
      payload: false
    }));
    
  } catch (error: any) {
    dispatch(authenticationActions.edit({
      type: EDIT_USER_FAIL,
      payload: error.message
    }))
  }
}