import DeleteButton from 'components/button/DeleteButton/DeleteButton';
import MainButton from 'components/button/MainButton/MainButton';
import TextInput from 'components/input/TextInput/TextInput';
import { useEffect, useRef, useState } from 'react';
import classes from './PageDataFormDetailList.module.scss';

type Props = {
  list: Array<string>
  isReadonly: boolean;
  handleListChange: (updatedList: Array<string>) => void;
  listTitle?: string;
  fieldTitle?: string;
}

const PageDataFormDetailList = ({
  list,
  isReadonly,
  handleListChange,
  listTitle,
  fieldTitle
}: Props) => {
  const elementRef = useRef<HTMLInputElement>(null);
  
  const [detailList, setDetailList] = useState<Array<string>>(list !== null ? list : []);
  let timeout: NodeJS.Timeout;
  useEffect(() => {
    handleListChange(detailList);
  }, [detailList, handleListChange])
  

  const deleteListElement = (index: number) => {
    const temp = [...detailList];
    temp.splice(index, 1);
    setDetailList(temp);
  }

  const addListElement = () => {
    const elementValue = elementRef.current!.value;
    setDetailList(prev => [...prev, elementValue.toString()]);
    elementRef.current!.value = '';
  }

  const changeHandler = (value: string, index?: number) => {
    if (!index?.toString()) {
      return;
    }

    if (timeout) {
      clearTimeout(timeout)
    }

    const newArray = [...detailList];
    newArray[index] = value;
    //setDetailList(newArray);

    timeout = setTimeout(() => {
      setDetailList(newArray);
    }, 1000);
  }

  const renderList = detailList.map((element, index) =>
    <div
      key={element + Math.random()}
      className={classes['details__element']}
    >
      <TextInput
        title={fieldTitle ? fieldTitle : 'Detail'}
        isReadonly={isReadonly}
        value={element}
        index={index}
        changeHandler={changeHandler}
      />
      {!isReadonly && <DeleteButton
        onClick={deleteListElement.bind(this, index)}
      />}
    </div>
  );

  return (
    <ul className={classes['details']}>
      {!isReadonly && <div className={classes['details__add']}>
        <TextInput
          title={listTitle ? listTitle : 'New Detail' }
          isReadonly={isReadonly}
          ref={elementRef}
        />
        <MainButton
          title='Add'
          isSubmit={false}
          onClick={addListElement}
        />
      </div>}

      {detailList !== null && detailList.length > 0 && renderList}
    </ul>
  )
}

export default PageDataFormDetailList