import { FERRY_FAIL, FERRY_REQUEST, FERRY_SUCCESS } from "data/constants/store/Ferry";
import { ferryActions } from "../ferry-slice";
import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { BACKEND_FERRY_ROUTE } from "data/routes/backendRoutes";
import { Ferry } from "data/interfaces/Ferry";

export const editFerry = (
  ferryArray: Array<Ferry>,
  index: number,
  title: string,
  hours: Array<string>
) => async (dispatch: Dispatch) => {
  dispatch(ferryActions.edit({ type: FERRY_REQUEST }));

  const tempFerryArray = [];

  if (ferryArray.length > 0) {
    ferryArray.forEach(value => {
      tempFerryArray.push(value);
    })
    tempFerryArray[index] = { title, hours } as Ferry;
  }

  const formData = new FormData();
  formData.append('allProms', JSON.stringify(tempFerryArray));
  formData.append('method', 'PUT');
  
  const editFerryRequest = async () => {
    return await axios.post(BACKEND_FERRY_ROUTE, formData, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });
  }

  try {
    const { data } = await editFerryRequest();
    console.log(data);
    
    dispatch(ferryActions.edit({
      type: FERRY_SUCCESS,
      payload: data
    }));
  } catch (error: any) {
    dispatch(ferryActions.edit({
      type: FERRY_FAIL,
      payload: error.response.data.message
    }))
  }
}