import classes from './MainModal.module.scss';
import { CSSTransition } from 'react-transition-group';
import Backdrop from 'components/Backdrop/Backdrop';
import { ReactNode, useRef } from 'react';
import CloseButton from 'components/button/CloseButton/CloseButton';
import { createPortal } from 'react-dom';

type Props = {
  activate: boolean;
  timeout: number;
  children: ReactNode;
  title?: string;
  close: () => void;
}

const MainModal = ({
  activate,
  timeout,
  title,
  children,
  close,
}: Props) => {
  const nodeRef = useRef(null);
  return createPortal(
    <>
      <Backdrop timeout={timeout} activate={activate} onClick={close} />
      <CSSTransition
        nodeRef={nodeRef}
        in={activate}
        timeout={timeout}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: classes['enter'],
          enterActive: classes['enter-active'],
          exit: classes['exit'],
          exitActive: classes['exit-active']
        }}
      >
        <div ref={nodeRef} className={classes['main-modal']}>
          <div className={classes['close-button-container']}>
            <CloseButton onClick={close} /> 
          </div>
            {title && <h1>{title}</h1>}
            {children}
        </div>
    </CSSTransition>
    </>
    , document.getElementById('react-portal')!)
}

export default MainModal