import { createSlice } from "@reduxjs/toolkit";
import { CONTACT_FAIL, CONTACT_REQUEST, CONTACT_SUCCESS } from "data/constants/store/contact";
import { Contact } from "data/interfaces/Contact";

const contactSlice = createSlice({
  name: 'contact',
  initialState: {
    loading: false,
    error: '',
    data: {} as Contact
  },
  reducers: {
    get(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case CONTACT_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case CONTACT_SUCCESS:
          state.loading = false;
          state.error = '';
          state.data = payload;
          break;
        case CONTACT_FAIL:
          state.loading = false;
          state.error = payload;
          break;
      }
    },
    edit(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case CONTACT_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case CONTACT_SUCCESS:
          state.loading = false;
          state.error = '';
          state.data = payload;
          break;
        case CONTACT_FAIL:
          state.loading = false;
          state.error = payload;
          break;
      }
    }
  }
})

export const contactActions = contactSlice.actions;
export default contactSlice;