import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { PRICE_FAIL, PRICE_REQUEST, PRICE_SUCCESS } from "data/constants/store/price";
import { BACKEND_SERVICE_ROUTE } from "data/routes/backendRoutes";
import { priceActions } from "../price-slice";

export const createPrice = (
  title: string,
  price: string,
  description: string
) => async (dispatch: Dispatch) => {
  dispatch(priceActions.create({ type: PRICE_REQUEST }));

  const formData = new FormData();
  formData.append('title', title);
  formData.append('description', description);
  formData.append('price', price);
  formData.append('method', 'POST');

  const createPriceRequest = async () => {
    return await axios.post(BACKEND_SERVICE_ROUTE, formData, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });
  }

  try {
    const {data} = await createPriceRequest();

    dispatch(priceActions.create({
      type: PRICE_SUCCESS,
      payload: data
    }));
  } catch (error: any) {
    dispatch(priceActions.create({
      type: PRICE_FAIL,
      payload: error.response.data.message
    }))
  }
}