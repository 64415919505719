import AdminHeader from 'components/header/AdminHeader/AdminHeader';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import classes from './Index.module.scss';
import { RootState } from 'store';
import { useEffect } from 'react';
import { getFAQ } from 'store/FAQ/actions/faq-get-action';
import SpinnerFullscreen from 'components/spinner/SpinnerFullscreen/SpinnerFullscreen';
import FAQCreate from './components/FAQCreate/FAQCreate';
import FAQForm from './components/FAQForm/FAQForm';
import { editFAQ } from 'store/FAQ/actions/faq-edit-action';
import { deleteFAQ } from 'store/FAQ/actions/faq-delete-action';

const FAQ = () => {
  const dispatch = useDispatch() as Dispatch<any>;

  const { loading, data } = useSelector((state: RootState) => state.faq);

  useEffect(() => {
    dispatch(getFAQ());
  }, [dispatch])

  const editFAQHandler = (id: string, title: string, description: string, index: number) => {
    dispatch(editFAQ(id, title, description, index))
  }

  const deleteFAQHandler = (id: string, index: number) => {
    dispatch(deleteFAQ(id, index))
  }

  const renderData = data.map((faq, index)=> (
    <FAQForm
      key={faq.id}
      id={faq.id}
      title={faq.title}
      description={faq.description}
      isEditable={true}
      dispatch={editFAQHandler}
      deleteHandler={deleteFAQHandler}
      index={index}
    />
  ))

  return (
    <AdminLayout>
      <AdminHeader />
      <section className={classes['faq-page']}>
        {loading && <SpinnerFullscreen isLoading={loading} />}
        <FAQCreate />
        {data.length <= 0 && <h1 className={classes['faq-page__info']}>No FAQ found</h1>}
        {data.length > 0 && renderData}
      </section>  
    </AdminLayout>
  )
}

export default FAQ