import classes from './FooterContact.module.scss';

type Props = {
  image: string;
  text: string;
}

const FooterContact = ({
  image,
  text
}: Props) => {
  return (
    <div className={classes['footer-contact']}>
      <img src={image} alt={image} className={classes['footer-contact__image']} />
      <span className={classes['footer-contact__text']}>{ text }</span>
    </div>
  )
}

export default FooterContact