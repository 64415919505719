import CloseButton from 'components/button/CloseButton/CloseButton';
import DeleteButton from 'components/button/DeleteButton/DeleteButton';
import EditButton from 'components/button/EditButton/EditButton';
import MainButton from 'components/button/MainButton/MainButton';
import TextAreaInput from 'components/input/TextAreaInput/TextAreaInput';
import TextInput from 'components/input/TextInput/TextInput';
import { FAQ } from 'data/interfaces/FAQ';
import { FormEvent, useRef, useState } from 'react';
import classes from './FAQForm.module.scss';


interface Props extends FAQ {
  isEditable: boolean;
  formTitle?: string;
  index: number;
  closeWindowHandler?: () => void;
  deleteHandler?: (id: string, index: number) => void;
  dispatch: (
    id: string,
    title: string,
    description: string,
    index: number
  ) => void
}

const FAQForm = ({
  id,
  isEditable,
  description,
  title,
  index,
  dispatch,
  closeWindowHandler,
  deleteHandler,
  formTitle
}: Props) => {

  const [isReadonly, setIsReadonly] = useState<boolean>(!isEditable ? false : true);
  
  const titleRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  
  const toggleReadonly = () => {
    setIsReadonly(!isReadonly);
  }

  const submitFormHandler = (event: FormEvent) => {
    event.preventDefault();

    const titleValue = titleRef.current!.value;
    const descriptionValue = descriptionRef.current!.value;

    dispatch(id, titleValue, descriptionValue, index);

    if (isEditable) {
      toggleReadonly();
    }
  }
  
  const formClass = !isReadonly
    ? `${classes['faq-form']} ${classes['faq-form--edit']}`
    : `${classes['faq-form']}`;
  
  return (
    <form
      className={formClass}
      onSubmit={submitFormHandler}
    >
      <div className={classes['faq-form__toolbar']}>
        {deleteHandler && !isReadonly &&
          <DeleteButton
            onClick={deleteHandler.bind(this, id, index)}
          />}
        
        {isEditable &&
          <EditButton
            isReadonly={isReadonly}
            onClick={toggleReadonly}
          />}
        
        {!isEditable && <CloseButton
          onClick={closeWindowHandler ? closeWindowHandler : () => {}}
        />}
      </div>

      {formTitle && <h1>{formTitle}</h1>}

      <TextInput
        ref={titleRef}
        title='Title'
        value={title}
      />

      <TextAreaInput
        ref={descriptionRef}
        title='Description'
        value={description}
      />
      
      {!isReadonly && 
        <MainButton
          title={isEditable ? 'EDIT' : 'CREATE'}
          isSubmit={true}
        />}
    </form>
  )
}

export default FAQForm