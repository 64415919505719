import { PageData } from 'data/interfaces/PageData';
// import { useSelector } from 'react-redux';
// import { RootState } from 'store';
import PageDataMenuElement from './components/PageDataMenuElement/PageDataMenuElement';
import classes from './PageDataMenu.module.scss';

type Props = {
  pageData: Array<PageData>
}

const PageDataMenu = ({
  pageData
}: Props) => {
  // const isMobile = useSelector((state: RootState) => state.client.isMobile);

  const renderPageData = pageData.map((data, index) => {
    // if (index >= 5 && !isMobile) {
    //   return null;
    // }

    return <PageDataMenuElement
      key={data.id}
      title={data.title}
      image={data.image_src}
    />
  })

  return (
    <div className={classes['page-data-menu']}>
      {renderPageData}
    </div>
  )
}

export default PageDataMenu