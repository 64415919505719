import LogoLink from 'components/link/LogoLink/LogoLink';
import { CLIENT_ADMIN_ROUTE } from 'data/routes/clientRoutes';
import classes from './AdminHeader.module.scss';
import LogoutButton from './components/LogoutButton';
import UserButton from './components/UserButton';

const AdminHeader = () => {
  return (
    <header className={classes['admin-header']}>
      <div className={classes['admin-header__logo']}>
        <LogoLink route={CLIENT_ADMIN_ROUTE} />
      </div>
      <div className={classes['admin-header__user']}>
        <UserButton />
        <LogoutButton />
      </div>
    </header>
  )
}

export default AdminHeader