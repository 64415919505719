import classes from './CreatePrice.module.scss';
import { CSSTransition } from 'react-transition-group';
import { useRef, useState } from 'react';
import MainButton from 'components/button/MainButton/MainButton';
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { createPrice } from 'store/price/actions/price-create-action';
import PriceForm from '../PriceForm/PriceForm';

const PriceCreate = () => {
  const dispatch = useDispatch() as Dispatch<any>;


  const [isForm, setIsForm] = useState<boolean>(false);
  const [isButton, setIsButton] = useState<boolean>(true);

  const toggleForm = () => {
    const timeoutTime = 210;

    if (isButton) {
      setIsButton(!isButton)
      setTimeout(() => {
        setIsForm(!isForm);
      }, timeoutTime);
      return;
    }

    if (isForm) {
      setIsForm(!isForm);
      setTimeout(() => {
        setIsButton(!isButton);
      }, timeoutTime);
      return;
    }
  }

  const createNewPrice = (id: string, title: string, price: string, description: string, index: number) => {
    console.log(description);
    toggleForm();
    dispatch(createPrice(title, price, description));
  }

  const nodeRef = useRef(null);
  const nodeRef2 = useRef(null);
  return (
    <div className={classes['create-price']}>
      <CSSTransition
        nodeRef={nodeRef}
        in={isButton}
        timeout={200}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: classes['enter'],
          enterActive: classes['enter-active'],
          exit: classes['exit'],
          exitActive: classes['exit-active']
        }}
      >
        <div
          className={classes['create-price__button']}
          ref={nodeRef}
        >
          <MainButton
            title={'Create new Price'}
            onClick={toggleForm}
          />
        </div>
      </CSSTransition>


      <CSSTransition
        nodeRef={nodeRef2}
        in={isForm}
        timeout={200}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: classes['enter'],
          enterActive: classes['enter-active'],
          exit: classes['exit'],
          exitActive: classes['exit-active']
        }}
      >
        <div
          className={classes['create-price__form']}
          ref={nodeRef2}
        >
          <PriceForm
            id="kek"
            isEditable={false}
            closeWindowHandler={toggleForm}
            formTitle={"Create new Price"}
            description={''}
            title={''}
            dispatch={createNewPrice}
            index={0}
            price={''}
          />
        </div>
      </CSSTransition>
    </div>
  )
}

export default PriceCreate