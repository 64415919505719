import PageTitleElement from 'components/elements/PageTitleElement/PageTitleElement';
import { Section } from 'data/interfaces/Section';
import PageLayout from 'layouts/PageLayout/PageLayout';
import { useSelector } from "react-redux";
import { RootState } from 'store';
import ContactData from './components/ContactData/ContactData';
import ContactForm from './components/ContactForm/ContactForm';
import classes from './Index.module.scss';

const KontaktPage = () => {
  const data = useSelector((state: RootState) => state.section.data);
  const kontaktData = data.length > 0 ? data[2] : { id: '', image_src: '', route: '', title: '' } as Section;
  
  return (
    <PageLayout>
      <section className={classes['kontakt-page']}>
        <PageTitleElement
          image={ kontaktData.image_src }
          title={ kontaktData.title }
        />
        <div className={classes['kontakt-page__contact-data']}>
          <ContactData />
          <ContactForm />
        </div>
        
      </section>
    </PageLayout>
  )
}

export default KontaktPage