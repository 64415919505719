import { createSlice } from "@reduxjs/toolkit";
import { SECTION_FAIL, SECTION_REQUEST, SECTION_SUCCESS } from "data/constants/store/section";
import { Section } from "data/interfaces/Section";

const sectionSlice = createSlice({
  name: 'section',
  initialState: {
    loading: false,
    error: '',
    data: [] as Array<Section>
  },
  reducers: {
    get(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case SECTION_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case SECTION_SUCCESS:
          state.loading = false;
          state.error = '';
          state.data = payload;
          break;
        case SECTION_FAIL:
          state.loading = false;
          state.error = payload;
          state.data = [];
          break;
      }
    },
    edit(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case SECTION_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case SECTION_SUCCESS:
          state.loading = false;
          state.error = '';
          state.data[payload.index] = payload.data;
          break;
        case SECTION_FAIL:
          state.loading = false;
          state.error = payload;
          state.data = [];
          break;
      }
    }
  }
})

export const sectionActions = sectionSlice.actions;
export default sectionSlice;