import { createSlice } from "@reduxjs/toolkit";
import { EDIT_USER_FAIL, EDIT_USER_REQUEST, EDIT_USER_SUCCESS } from "data/constants/store/editUser";
import { GET_USER_FAIL, GET_USER_REQUEST, GET_USER_SUCCESS } from "data/constants/store/getUser";
import { LOGOUT_FAIL, LOGOUT_REQUEST, LOGOUT_SUCCESS } from "data/constants/store/logout";
import { LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS } from "../../data/constants/store/login";

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    loading: false,
    error: '',
    isAuthorized: false
  },
  reducers: {
    login(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case LOGIN_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case LOGIN_SUCCESS:
          state.loading = false;
          state.error = '';
          state.isAuthorized = payload;
          break;
        case LOGIN_FAIL:
          state.loading = false;
          state.error = payload;
          state.isAuthorized = false;
          break;
      }
    },
    get(state, action) {
      const { type, payload } = action.payload;

      switch (type) {
        case GET_USER_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case GET_USER_SUCCESS:
          state.loading = false;
          state.error = '';
          state.isAuthorized = payload;
          break;
        case GET_USER_FAIL:
          state.loading = false;
          state.error = payload;
          state.isAuthorized = false;
          break;
      }
    },
    logout(state, action) {
      const { type, payload } = action.payload;

      switch (type) {
        case LOGOUT_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case LOGOUT_SUCCESS:
          state.loading = false;
          state.error = '';
          state.isAuthorized = payload;
          break;
        case LOGOUT_FAIL:
          state.loading = false;
          state.error = payload;
          state.isAuthorized = false;
          break;
      }
    },
    edit(state, action) {
      const { type, payload } = action.payload;

      switch (type) {
        case EDIT_USER_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case EDIT_USER_SUCCESS:
          state.loading = false;
          state.error = '';
          state.isAuthorized = payload;
          break;
        case EDIT_USER_FAIL:
          state.loading = false;
          state.error = payload;
          state.isAuthorized = false;
          break;
      }
    }
  }
})

export const authenticationActions = authenticationSlice.actions;
export default authenticationSlice;