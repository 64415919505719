import EditButton from 'components/button/EditButton/EditButton';
import MainButton from 'components/button/MainButton/MainButton';
import TextInput from 'components/input/TextInput/TextInput';
import { Contact } from 'data/interfaces/Contact';
import { FormEvent, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import classes from './ContactDataFrom.module.scss';
import { editContact } from 'store/contact/actions/contact-edit-request';

interface Props extends Contact {}

const ContactDataFrom = ({
  city,
  email,
  phone_number,
  postal_code,
  street,
  home_number,
  id,
  facebook,
  instagram,
  google_maps,
  google_my_business,
  whatsapp,
  youtube,
  city2,
  home_number2,
  postal_code2,
  street2
}: Props) => {
  const dispatch = useDispatch() as Dispatch<any>;

  const cityRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const postalCodeRef = useRef<HTMLInputElement>(null);
  const streetRef = useRef<HTMLInputElement>(null);
  const homeNumberRef = useRef<HTMLInputElement>(null);
  const facebookRef = useRef<HTMLInputElement>(null);
  const instagramRef = useRef<HTMLInputElement>(null);
  const whatsappRef = useRef<HTMLInputElement>(null);
  const googleBuisnessRef = useRef<HTMLInputElement>(null);
  const youTubeRef = useRef<HTMLInputElement>(null);
  const city2Ref = useRef<HTMLInputElement>(null);
  const postalCode2Ref = useRef<HTMLInputElement>(null);
  const street2Ref = useRef<HTMLInputElement>(null);
  const homeNumber2Ref = useRef<HTMLInputElement>(null);

  const [isReadonly, setIsReadonly] = useState<boolean>(true);

  const toggleReadonly = () => {
    setIsReadonly(!isReadonly);
  }

  const editContactHandler = (event: FormEvent) => {
    event.preventDefault();

    const cityValue = cityRef.current!.value;
    const emailValue = emailRef.current!.value;
    const phoneNumberValue = phoneNumberRef.current!.value;
    const postalCodeValue = postalCodeRef.current!.value;
    const streetValue = streetRef.current!.value;
    const homeNumberValue = homeNumberRef.current!.value;
    const facebookValue = facebookRef.current!.value;
    const instagramValue = instagramRef.current!.value;
    const whatsappValue = whatsappRef.current!.value;
    const googleBuisnValue = googleBuisnessRef.current!.value;
    const youTubeValue = youTubeRef.current!.value;
    const city2Value = city2Ref.current!.value;
    const postalCode2Value = postalCode2Ref.current!.value;
    const street2Value = street2Ref.current!.value;
    const homeNumber2Value = homeNumber2Ref.current!.value;

    dispatch(editContact(
      phoneNumberValue,
      emailValue,
      streetValue,
      cityValue,
      postalCodeValue,
      homeNumberValue,
      facebookValue,
      instagramValue,
      googleBuisnValue,
      whatsappValue,
      youTubeValue,
      city2Value,
      street2Value,
      postalCode2Value,
      homeNumber2Value
    ))
  }

  const contactClass = isReadonly
    ? `${classes['contact']}`
    : `${classes['contact']} ${classes['contact--active']}`
  return (
    <form
      className={contactClass}
      onSubmit={editContactHandler}
    >
      <div className={classes['contact__toolbar']}>
        <EditButton
          isReadonly={isReadonly}
          onClick={toggleReadonly}
        />
      </div>

      <h1>Contact Data</h1>
      <TextInput
        ref={postalCodeRef}
        title={'Postal code'}
        value={postal_code}
        isReadonly={isReadonly}
      />

      <TextInput
        ref={cityRef}
        title={'City'}
        value={city}
        isReadonly={isReadonly}
      />

      <TextInput
        ref={streetRef}
        title={'Street'}
        value={street}
        isReadonly={isReadonly}
      />

      <TextInput
        ref={homeNumberRef}
        title={'Home number'}
        value={home_number}
        isReadonly={isReadonly}
      />

      <TextInput
        ref={postalCode2Ref}
        title={'Postal code 2'}
        value={postal_code2}
        isReadonly={isReadonly}
      />

      <TextInput
        ref={city2Ref}
        title={'City 2'}
        value={city2}
        isReadonly={isReadonly}
      />

      <TextInput
        ref={street2Ref}
        title={'Street 2'}
        value={street2}
        isReadonly={isReadonly}
      />

      <TextInput
        ref={homeNumber2Ref}
        title={'Home number 2'}
        value={home_number2}
        isReadonly={isReadonly}
      />

      <TextInput
        ref={emailRef}
        title={'Email'}
        value={email}
        isReadonly={isReadonly}
      />

      <TextInput
        ref={phoneNumberRef}
        title={'Phone number'}
        value={phone_number}
        isReadonly={isReadonly}
      />

      <TextInput
        ref={facebookRef}
        title={'Facebook'}
        value={facebook}
        isReadonly={isReadonly}
      />

      <TextInput
        ref={instagramRef}
        title={'Instagram'}
        value={instagram}
        isReadonly={isReadonly}
      />

      <TextInput
        ref={whatsappRef}
        title={'Whatsapp'}
        value={whatsapp}
        isReadonly={isReadonly}
      />

      <TextInput
        ref={googleBuisnessRef}
        title={'Google my business'}
        value={google_my_business}
        isReadonly={isReadonly}
      />

      <TextInput
        ref={youTubeRef}
        title={'YouTube'}
        value={youtube}
        isReadonly={isReadonly}
      />
      
      {!isReadonly && 
        <MainButton
          title={'EDIT'}
          isSubmit={true}
        />
      }
    </form>
  )
}

export default ContactDataFrom