import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { FAQ_FAIL, FAQ_REQUEST, FAQ_SUCCESS } from "data/constants/store/FAQ";
import { BACKEND_FAQ_ROUTE } from "data/routes/backendRoutes";
import { faqActions } from "../faq-slice";

export const createFAQ = (
  title: string,
  description: string
) => async (dispatch: Dispatch) => {
  dispatch(faqActions.create({ type: FAQ_REQUEST }));

  const formData = new FormData();
  formData.append('title', title);
  formData.append('description', description);
  formData.append('method', 'POST');

  const createFAQRequest = async () => {
    return await axios.post(BACKEND_FAQ_ROUTE, formData, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });
  }

  try {
    const {data} = await createFAQRequest();

    dispatch(faqActions.create({
      type: FAQ_SUCCESS,
      payload: data
    }));
  } catch (error: any) {
    dispatch(faqActions.create({
      type: FAQ_FAIL,
      payload: error.response.data.message
    }))
  }
}