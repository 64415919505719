type Props = {
  link: string;
}

const GoogleMaps = ({link}: Props) => {
  return (
    <iframe
      title={"goolge"}
      src={link}
      width="600"
      height="450"
      frameBorder="0"
      style={{width: '100%', height: '100%'}}
      allowFullScreen={false}
      aria-hidden="false"
      tabIndex={0}
      loading="lazy"
    />
  )
}

export default GoogleMaps