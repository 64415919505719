import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { LOGOUT_FAIL, LOGOUT_REQUEST, LOGOUT_SUCCESS } from "data/constants/store/logout";
import { BACKEND_LOGOUT_ROUTE } from "data/routes/backendRoutes";
import { authenticationActions } from "../authentication-slice";

export const logoutUser = () => async (dispatch: Dispatch) => {
  dispatch(authenticationActions.logout({ type: LOGOUT_REQUEST }));

  const logoutRequest = async () => {
    return axios.post(BACKEND_LOGOUT_ROUTE, {}, { withCredentials: true })
  }

  try {
    const { data } = await logoutRequest();
    
    dispatch(authenticationActions.logout({
      type: LOGOUT_SUCCESS,
      payload: data.is_authorized
    }));
    
  } catch (error: any) {
    dispatch(authenticationActions.logout({
      type: LOGOUT_FAIL,
      payload: error.message
    }))
  }
}