import Backdrop from 'components/Backdrop/Backdrop';
import { useRef } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { RootState } from 'store';
import HamburgerModalNavLink from './components/HamburgerModalNavLink';
import classes from './HamburgerModal.module.scss';

type Props = {
  isActive: boolean;
  close: () => void;
}

const HamburgerModal = ({
  isActive,
  close
}: Props) => {
  const sectionData = useSelector((state: RootState) => state.section.data);

  const renderLinks = sectionData.map(section => (
    <HamburgerModalNavLink
      key={section.id}
      route={section.route}
      title={section.title}
    />
  ))

  const nodeRef = useRef(null);
  return createPortal(
    <>
      <Backdrop timeout={200} activate={isActive} onClick={close} />
      <CSSTransition
        nodeRef={nodeRef}
        in={isActive}
        timeout={200}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: classes['enter'],
          enterActive: classes['enter-active'],
          exit: classes['exit'],
          exitActive: classes['exit-active']
        }}
      >
        <nav ref={nodeRef} className={classes['hamburger-modal']}>
          {renderLinks}
        </nav>
    </CSSTransition>
    </>
    , document.getElementById('menu')!)
}

export default HamburgerModal