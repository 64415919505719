import { useRef, useState } from 'react';
import classes from './FuckElement.module.scss';
import { CSSTransition } from 'react-transition-group';

type Props = {
  title: string;
  description: string;
}

const FuckElement = ({
  description,
  title
}: Props) => {

  const [isActive, setIsActive] = useState<boolean>(false);

  const toggleFaqActive = () => {
    setIsActive(!isActive);
  }
  //change to ul like in price list
  const nodeRef = useRef(null);
  const faqQuestionClass = isActive
    ? `${classes['faq-question']} ${classes['faq-question--active']}`
    : `${classes['faq-question']}`;
  return (
    <div className={faqQuestionClass}>
      <div
        className={classes['faq-question__head']}
        onClick={toggleFaqActive}
      >
        <button
          className={classes['faq-question__head-button']}
          onClick={toggleFaqActive}
        />
        <h1
          className={classes['faq-question__head-title']}
        >
          {title}
        </h1>
      </div>
      <CSSTransition
      nodeRef={nodeRef}
      in={isActive}
      timeout={150}
      mountOnEnter
      unmountOnExit
      classNames={{
        enter: classes['enter'],
        enterActive: classes['enter-active'],
        exit: classes['exit'],
        exitActive: classes['exit-active']
      }}
      >
        <div className={classes['faq-question__body']} ref={nodeRef}>
          <span className={classes['faq-question__body-text']}>
            {description}
          </span>
        </div>
      </CSSTransition>
      
    </div>
  )
}

export default FuckElement