import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { SECTION_FAIL, SECTION_REQUEST, SECTION_SUCCESS } from "data/constants/store/section";
import { BACKEND_SECTION_ROUTE } from "data/routes/backendRoutes";
import { sectionActions } from "../section-slice";

export const getSections = () => async (dispatch: Dispatch) => {
  dispatch(sectionActions.get({ type: SECTION_REQUEST }));

  const getSectionsRequest = async () => {
    return await axios.get(BACKEND_SECTION_ROUTE);
  }

  try {
    const {data} = await getSectionsRequest();
    
    dispatch(sectionActions.get({
      type: SECTION_SUCCESS,
      payload: data
    }));
  } catch (error: any) {
    dispatch(sectionActions.get({
      type: SECTION_FAIL,
      payload: error.response.data.message
    }))
  }
}