import { FormEvent, useRef } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { loginUser } from '../../../../../store/authentication/actions/authentication-login-action';
import classes from './LoginForm.module.scss';
import LogoImage from "components/image/LogoImage/LogoImage";
import TextInput from "components/input/TextInput/TextInput";
import PasswordInput from "components/input/PasswordInput/PasswordInput";
import MainButton from "components/button/MainButton/MainButton";
import { useSelector } from "react-redux";
import { RootState } from "store";
import Spinner from "components/spinner/Spinner/Spinner";

const LoginForm = () => {
  const dispatch = useDispatch() as Dispatch<any>;

  const { error, loading } = useSelector((state: RootState) => state.authentication);

  const loginRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const loginUserHandler = (event: FormEvent) => {
    event.preventDefault();

    const loginValue = loginRef.current!.value;
    const passwordValue = passwordRef.current!.value;
    
    if (!loginValue && !passwordValue) {
      return;
    }
    
    dispatch(loginUser(loginValue, passwordValue));
  }


  return (
    <form
      onSubmit={loginUserHandler}
      className={classes['login-form']}
    >
      {!loading && <>
        <div className={classes['login-form__logo-wrapper']}>
          <LogoImage />
        </div>

        <TextInput
          ref={loginRef}
          title={'login'}
          isRequired={true}
        />
        <PasswordInput
          ref={passwordRef}
          title={'password'}
          isRequired={true}
        />
        {error && <p className={classes['login-form__error']}>{error}</p>}
        <MainButton
          title={'Login'}
          isSubmit={true}
        />
      </>}
      {loading && <Spinner size={"10rem"} borderSize={".7rem"} color={"white"} />}
    </form>
  )
}

export default LoginForm;