import AdminHeader from 'components/header/AdminHeader/AdminHeader';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import classes from './Index.module.scss';
import { useEffect } from 'react';
import { getSections } from 'store/section/actions/section-get-action';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import SpinnerFullscreen from 'components/spinner/SpinnerFullscreen/SpinnerFullscreen';
import SectionForm from './components/SectionForm/SectionForm';

const Section = () => {
  const dispatch = useDispatch() as Dispatch<any>;

  const { loading, data } = useSelector((state: RootState) => state.section);

  useEffect(() => {
    dispatch(getSections());
  }, [dispatch])
  
  const renderSections = data.map((section, index) => (
    <SectionForm
      key={section.id}
      id={section.id}
      image_src={section.image_src}
      route={section.route}
      title={section.title}
      index={index}
    />
  ))

  return (
    <AdminLayout>
      <AdminHeader />
      <section className={classes['section-page']}>
        {loading && <SpinnerFullscreen isLoading={loading} />}
        {!loading && renderSections}
      </section>  
    </AdminLayout>
  )
}

export default Section