import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { RootState } from 'store';
import { getPrice } from 'store/price/actions/price-get-action';
import PriceListElement from '../PriceListElement/PriceListElement';
import classes from './PriceList.module.scss';

const PriceList = () => {
  const dispatch = useDispatch() as Dispatch<any>;
  const prices = useSelector((state: RootState) => state.price.data);

  useEffect(() => {
    dispatch(getPrice());
  }, [dispatch])

  const renderList = prices.map(priceElement => (
    <PriceListElement
      key={priceElement.id}
      title={priceElement.title}
      description={priceElement.description}
      price={priceElement.price}
    />
  ))
  return (
    <ul className={classes['price-list']}>
      {prices.length > 0 && renderList}
    </ul>
  )
}

export default PriceList