import classes from './ContactDataData.module.scss';

type Props = {
  image: string;
  text: string;
}

const ContactDataData = ({
  image,
  text
}: Props) => {
  return (
    <div className={classes['contact-data']}>
      <img src={image} alt={image} className={classes['contact-data__image']} />
      <span className={classes['contact-data__text']}>{ text }</span>
    </div>
  )
}

export default ContactDataData