import classes from './EditButton.module.scss';
import penIcon from 'assets/images/icon/black/penIconFull.svg';
import closeIcon from 'assets/images/icon/black/closeIcon.svg';

interface Props {
  isReadonly: boolean;
  onClick: () => void;
}

const EditButton = ({
  isReadonly,
  onClick
}: Props) => {
  return (
    <button
      className={classes['edit-button']}
      type='button'
      onClick={onClick}
      style={{
        backgroundImage: `url('${isReadonly ? penIcon : closeIcon}')`
      }}
    />
  )
}

export default EditButton