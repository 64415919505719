import PageLayout from 'layouts/PageLayout/PageLayout';
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { RootState } from 'store';
import classes from './Index.module.scss';
import { Section } from 'data/interfaces/Section';
import PageTitleElement from 'components/elements/PageTitleElement/PageTitleElement';
import SpinnerFullscreen from 'components/spinner/SpinnerFullscreen/SpinnerFullscreen';
import { getPageData } from 'store/pageData/actions/page-data-get-action';
import { useEffect } from 'react';
import PageDataElement from 'components/elements/PageDataElement/PageDataElement';
import PriceList from './components/PriceList/PriceList';
import Slider from 'components/Slider/Slider';
import { getSlider } from 'store/slider/actions/get-slider-action';

const ServicePage = () => {
  const dispatch = useDispatch() as Dispatch<any>;

  const data = useSelector((state: RootState) => state.section.data);
  const serviceData = data.length > 0 ? data[3] : { id: '', image_src: '', route: '', title: '' } as Section;
  const { data: pageData, loading } = useSelector((state: RootState) => state.pageData);

  const { loading: sliderLoading, data: sliderData } = useSelector((state: RootState) => state.slider);

  useEffect(() => {
    dispatch(getSlider('4'));
  }, [dispatch])

  useEffect(() => {
    dispatch(getPageData('4'));
  }, [dispatch, serviceData.id])

  const renderPageData = pageData.map(data => {
    return <PageDataElement
      key={data.id}
      image={data.image_src}
      title={data.title}
      text={data.text}
      list={data.list}
    />
  })

  return (
    <PageLayout>
      <section className={classes['service-page']}>
      <PageTitleElement
          image={ serviceData.image_src }
          title={ serviceData.title }
        />
        {loading && <SpinnerFullscreen isLoading={loading} />}
        {!loading && renderPageData}
        <PriceList />

        {!sliderLoading && sliderData.length > 0 && sliderData[0].slides.length > 0 && <Slider
          slides={sliderData[0].slides}
          isBorderRadious={true}
        />}
      </section>
    </PageLayout>
  )
}

export default ServicePage