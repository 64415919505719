import { SelectInputOptions } from 'data/interfaces/SeletcDataOptions';
import useAssignRefs from 'hooks/useAssignRefs';
import { ChangeEvent, forwardRef, useEffect, useId, useRef, useState } from 'react';
import InputWrapper from '../InputWrapper/InputWrapper';

type Props = {
  options: Array<SelectInputOptions>;
  title: string;
  selectedValue?: string;
  onChange?: (value: string) => void;
}

const SelectInput = forwardRef<HTMLSelectElement, Props>(({
  options,
  title,
  selectedValue,
  onChange
}, ref) => {
  const inputLocalRef = useRef<HTMLSelectElement>(null);
  const inputRef = useAssignRefs(inputLocalRef, ref);

  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [value, setValue] = useState<string>(selectedValue ? selectedValue : '');

  useEffect(() => {
    if (selectedValue) {
      setValue(selectedValue)
    }
  }, [selectedValue])
  

  const focusInput = () => {
    inputLocalRef.current?.focus();
    setFocus();
  }

  const setFocus = () => {
    setIsFocus(true);
  }

  const setBlur = () => {
    setIsFocus(false);
  }

  const onChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    const currentValue = event.target!.value;
    
    setValue(currentValue);

    if (onChange) {
      onChange(currentValue);
    }
  }

  const renderOptions = options.map(option => (
   <option key={option.value} value={option.value}>{option.title}</option>
  ))

  const inputId = useId();
  return (
    <InputWrapper
      focusInput={focusInput}
      isInputFocus={isFocus}
      title={title}
      inputId={inputId}
      isValid={true}
    >
      <select
        ref={inputRef}
        id={inputId}
        onBlur={setBlur}
        value={value}
        onChange={onChangeHandler}
      >
        {options.length > 0 && renderOptions}
      </select>
    </InputWrapper>
  )
})

export default SelectInput