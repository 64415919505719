import classes from './ContactDataSocial.module.scss';

type Props = {
  image: string;
  link: string;
}

const ContactDataSocial = ({
  image,
  link
}: Props) => {
  return (
    <a
      className={classes['social']}
      href={link}
    >
      <img src={image} alt={link} />
    </a>
  )
}

export default ContactDataSocial