import AdminHeader from 'components/header/AdminHeader/AdminHeader';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import classes from './Index.module.scss';
import { useEffect, useState } from 'react';
import { getPageData } from 'store/pageData/actions/page-data-get-action';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import SpinnerFullscreen from 'components/spinner/SpinnerFullscreen/SpinnerFullscreen';
import CreatePageData from './components/CreatePageData/CreatePageData';
import { getSections } from 'store/section/actions/section-get-action';
import PageDataForm from './components/PageDataForm/PageDataForm';
import { editPageData } from 'store/pageData/actions/page-data-edit-action';
import { deletePageData } from 'store/pageData/actions/page-data-delete-action';
import { SelectInputOptions } from 'data/interfaces/SeletcDataOptions';
import SelectInput from 'components/input/SelectInput/SelectInput';

const PageData = () => {
  const dispatch = useDispatch() as Dispatch<any>;

  const { loading, data } = useSelector((state: RootState) => state.pageData);
  const { data: sectionData, loading: sectionLoading } = useSelector((state: RootState) => state.section);
  
  const [selectedSection, setSelectedSection] = useState<string>('');

  useEffect(() => {
    if (sectionData.length <= 0) {
      dispatch(getSections());
    }
  }, [dispatch, sectionData])

  useEffect(() => {
    if (selectedSection) {
      dispatch(getPageData(selectedSection));
      return;
    }
    dispatch(getPageData());
  }, [dispatch, selectedSection])

  const editPageDataHandler = (
    index: number,
    id: string,
    sectionId: string,
    title: string,
    text: string,
    list?: Array<string>,
    googleMaps?: string,
    image?: File,
  ) => {
    dispatch(editPageData(id, sectionId, index, title, text, list, googleMaps, image));
    setSelectedSection(sectionId);
  }

  const deletePageDataHandler = (id: string) => {
    dispatch(deletePageData(id));
  }

  const onSelectChangeHandler = (value: string) => { 
    setSelectedSection(value);
  }

  const selectOptions = [] as Array<SelectInputOptions>;
  sectionData.forEach(((section, index) => {
    if (index < 5 && index !== 2) {
      const sectionOption = {
        title: section.title,
        value: section.id
      } as SelectInputOptions

      selectOptions.push(sectionOption);
    }
  }))

  selectOptions.unshift({ title: 'All', value: '' });
  
  const renderData = data.map((pageData, index) => (
    <PageDataForm
      key={pageData.id + Math.random()}
      id={pageData.id}
      image_src={pageData.image_src}
      index={index}
      sectionData={sectionData}
      text={pageData.text}
      title={pageData.title}
      section_id={pageData.section_id}
      isEditable={true}
      dispatch={editPageDataHandler}
      formTitle={pageData.title}
      deleteHandler={deletePageDataHandler}
      list={pageData.list}
      map_url={pageData.map_url}
    />
  ))

  return (
    <AdminLayout>
      <AdminHeader />
      <section className={classes['page-data-page']}>
        {(loading || sectionLoading) && <SpinnerFullscreen isLoading={loading} />}
        <>
          <CreatePageData
            sectionData={sectionData}
            onDispatch={onSelectChangeHandler}
          />

          {!sectionLoading && <div className={classes['page-data-page__select']}>
            <SelectInput
              options={selectOptions}
              title='Show by Section'
              onChange={onSelectChangeHandler}
              selectedValue={selectedSection}
            />
          </div>}

          {(!loading || !sectionLoading) && data.length > 0 && <>
            <div className={classes['page-data-page__content']}>
              {renderData}
            </div>
          </>}
          {data.length <= 0 && <h1 className={classes['page-data-page__info']}>No Page Data found</h1>}
        </>
      </section>  
    </AdminLayout>
  )
}

export default PageData;