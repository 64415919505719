import { createSlice } from "@reduxjs/toolkit";
import { MESSAGE_FAIL, MESSAGE_REQUEST, MESSAGE_SUCCESS } from "data/constants/store/message";
import { Message } from "data/interfaces/Message";

const messageSlice = createSlice({
  name: 'message',
  initialState: {
    loading: false,
    error: '',
    data: [] as Array<Message>
  },
  reducers: {
    get(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case MESSAGE_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case MESSAGE_SUCCESS:
          state.loading = false;
          state.error = '';
          state.data = payload;
          break;
        case MESSAGE_FAIL:
          state.loading = false;
          state.error = payload;
          break;
      }
    },
    create(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case MESSAGE_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case MESSAGE_SUCCESS:
          state.loading = false;
          state.error = '';
          break;
        case MESSAGE_FAIL:
          state.loading = false;
          state.error = payload;
          break;
      }
    },
    delete(state, action) {
      const { type, payload } = action.payload;
      switch (type) {
        case MESSAGE_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case MESSAGE_SUCCESS:
          state.loading = false;
          state.error = '';
          if (payload !== undefined) {
            state.data = state.data.filter(message => message.id !== payload);
          }
          break;
        case MESSAGE_FAIL:
          state.loading = false;
          state.error = payload;
          break;
      }
    }
  }
})

export const messageActions = messageSlice.actions;
export default messageSlice;