import AdminHeader from 'components/header/AdminHeader/AdminHeader';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import classes from './Index.module.scss';
import { useEffect } from 'react';
import { getMessage } from 'store/messages/actions/message-get-action';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import SpinnerFullscreen from 'components/spinner/SpinnerFullscreen/SpinnerFullscreen';
import MessageBox from './components/MessageBox/MessageBox';

const Messages = () => {
  const dispatch = useDispatch() as Dispatch<any>;

  const { loading, data } = useSelector((state: RootState) => state.message);

  useEffect(() => {
    dispatch(getMessage());
  }, [dispatch])

  const renderMessages = data.map(message => (
    <MessageBox
      key={message.id}
      id={message.id}
      message={message.message}
      email={message.email}
      phone={message.phone}
    />
  ))

  return (
    <AdminLayout>
      <AdminHeader />
      <section className={classes['messages-page']}>
        {loading && <SpinnerFullscreen isLoading={loading} />}
        {!loading && data.length > 0 && renderMessages}
        {data.length <= 0 && <h1 className={classes['messages-page__info']}>No Messages found</h1>}
      </section>  
    </AdminLayout>
  )
}

export default Messages;