import LogoLink from 'components/link/LogoLink/LogoLink';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import classes from './Footer.module.scss';
import phoneIcon from 'assets/images/icon/black/phoneIcon.svg';
import emailIcon from 'assets/images/icon/black/emailIcon.svg';
import locationIcon from 'assets/images/icon/black/locationIcon.svg';
import FooterContact from './components/FooterContact';
import facebookIcon from 'assets/images/icon/black/facebookIcon.svg';
import instagramIcon from 'assets/images/icon/black/instaIcon.svg';
import whatsappIcon from 'assets/images/icon/black/whatsappIcon.svg';
import youtubeIcon from 'assets/images/icon/black/youtubeIcon.svg';
import ContactDataSocial from 'pages/KontaktPage/components/ContactDataSocial/ContactDataSocial';

const Footer = () => {
  const contactData = useSelector((state: RootState) => state.contact.data);
  const {
    phone_number,
    email,
    street,
    home_number,
    postal_code,
    city,
    city2,
    home_number2,
    postal_code2,
    street2
  } = contactData;

  const date = new Date();
  
  const locationData = `${street} ${home_number}, ${postal_code} ${city}`;
  const locationData2 = `${street2} ${home_number2}, ${postal_code2} ${city2}`;
  const copyrightText = `© ${date.getFullYear()} amhauslowe`;

  return (
    <footer
      className={classes.footer}
    >
      <div className={classes['footer-content']}>
        <div className={classes['footer__socials']}>
          {contactData.whatsapp && <ContactDataSocial
            image={whatsappIcon}
            link={contactData.whatsapp}
          />}
          {contactData.facebook && <ContactDataSocial
            image={facebookIcon}
            link={contactData.facebook}
          />}
          {contactData.instagram && <ContactDataSocial
            image={instagramIcon}
            link={contactData.instagram}
          />}
          {contactData.youtube && <ContactDataSocial
            image={youtubeIcon}
            link={contactData.youtube}
          />}
        </div>
        <div className={classes['footer__logo']}>
          <LogoLink route={'/'} />
        </div>
        <div className={classes['footer__contact']}>
          <FooterContact
            image={phoneIcon}
            text={phone_number}
          />
          <FooterContact
            image={emailIcon}
            text={email}
          />
          <FooterContact
            image={locationIcon}
            text={locationData}
          />
          <FooterContact
            image={locationIcon}
            text={locationData2}
          />
        </div>
      </div>
      <div className={classes['footer__copyright']}>
        <span className={classes['footer__copyright-text']}>{copyrightText}</span>
        <span>created and designed by:</span>
        <span>Przemysław Niemiec</span>
        <a href='https://www.instagram.com/szopenscript/'>SzopenScript</a>
      </div>
    </footer>
  )
}

export default Footer