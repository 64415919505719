import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { CONTACT_FAIL, CONTACT_REQUEST, CONTACT_SUCCESS } from "data/constants/store/contact";
import { BACKEND_CONTACT_ROUTE } from "data/routes/backendRoutes";
import { contactActions } from "../contact-slice";

export const getContact = () => async (dispatch: Dispatch) => {
  dispatch(contactActions.get({ type: CONTACT_REQUEST }));

  const getContact = async () => {
    return await axios.get(BACKEND_CONTACT_ROUTE);
  }

  try {
    const {data} = await getContact();

    dispatch(contactActions.get({
      type: CONTACT_SUCCESS,
      payload: data
    }));
  } catch (error: any) {
    dispatch(contactActions.get({
      type: CONTACT_FAIL,
      payload: error.response.data.message
    }))
  }
}