import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_USER_FAIL, GET_USER_REQUEST, GET_USER_SUCCESS } from "data/constants/store/getUser";
import { BACKEND_LOGIN_ROUTE } from "data/routes/backendRoutes";
import { authenticationActions } from "../authentication-slice";

export const getUser = () => async (dispatch: Dispatch) => {
  dispatch(authenticationActions.get({ type: GET_USER_REQUEST }));

  const getUserRequest = async () => {
    return await axios.get(`${BACKEND_LOGIN_ROUTE}`, { withCredentials: true });
  }

  try {
    const {data} = await getUserRequest();
    
    dispatch(authenticationActions.get({
      type: GET_USER_SUCCESS,
      payload: data.is_authorized
    }));
    
  } catch (error: any) {
    dispatch(authenticationActions.get({
      type: GET_USER_FAIL,
      payload: error.message
    }))
  }
}