import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { PRICE_FAIL, PRICE_REQUEST, PRICE_SUCCESS } from "data/constants/store/price";
import { BACKEND_SERVICE_ROUTE } from "data/routes/backendRoutes";
import { priceActions } from "../price-slice";


export const deletePrice = (
  id: string,
  index: number
) => async (dispatch: Dispatch) => {
  dispatch(priceActions.delete({ type: PRICE_REQUEST }));

  const data = {
    id,
    method: 'DELETE'
  }

  const deletePriceRequest = async () => {
    return await axios.post(BACKEND_SERVICE_ROUTE, data, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });
  }


  try {
    await deletePriceRequest();

    dispatch(priceActions.delete({
      type: PRICE_SUCCESS,
      payload: index
    }));
  } catch (error: any) {
    dispatch(priceActions.delete({
      type: PRICE_FAIL,
      payload: error.response.data.message
    }))
  }
}