import { useRef, useState } from 'react';
import classes from './PriceListElement.module.scss';
import { CSSTransition } from 'react-transition-group';

type Props = {
  title: string;
  description: string;
  price: string;
}

const PriceListElement = ({
  description,
  title,
  price
}: Props) => {

  const [isActive, setIsActive] = useState<boolean>(false);

  const toggleFaqActive = () => {
    setIsActive(!isActive);
  }

  const nodeRef = useRef(null);
  const faqQuestionClass = isActive
    ? `${classes['price-element']} ${classes['price-element--active']}`
    : `${classes['price-element']}`;
  return (
    <li className={faqQuestionClass}>
      <div
        className={classes['price-element__head']}
        onClick={toggleFaqActive}
      >
        <button
          className={classes['price-element__head-button']}
          onClick={toggleFaqActive}
        />
        <h1
          className={classes['price-element__head-title']}
        >
          {title}
        </h1>
        <span
          className={classes['price-element__head-price']}
        >
          {price}
        </span>
      </div>
      <CSSTransition
      nodeRef={nodeRef}
      in={isActive}
      timeout={150}
      mountOnEnter
      unmountOnExit
      classNames={{
        enter: classes['enter'],
        enterActive: classes['enter-active'],
        exit: classes['exit'],
        exitActive: classes['exit-active']
      }}
      >
        <div className={classes['price-element__body']} ref={nodeRef}>
          <span className={classes['price-element__body-text']}>
            {description}
          </span>
        </div>
      </CSSTransition>
      
    </li>
  )
}

export default PriceListElement