import CloseButton from 'components/button/CloseButton/CloseButton';
import DeleteButton from 'components/button/DeleteButton/DeleteButton';
import EditButton from 'components/button/EditButton/EditButton';
import MainButton from 'components/button/MainButton/MainButton';
import FileInput from 'components/input/FileInput/FileInput';
import SelectInput from 'components/input/SelectInput/SelectInput';
import TextAreaInput from 'components/input/TextAreaInput/TextAreaInput';
import TextInput from 'components/input/TextInput/TextInput';
import { Section } from 'data/interfaces/Section';
import { SelectInputOptions } from 'data/interfaces/SeletcDataOptions';
import { Slide } from 'data/interfaces/Slide';
import { backendUrl } from 'data/routes/backendRoutes';
import { FormEvent, useRef, useState } from 'react';
import classes from './SlideForm.module.scss';


interface Props extends Slide {
  isEditable: boolean;
  formTitle?: string;
  index: number;
  sectionData: Array<Section>;
  closeWindowHandler?: () => void;
  deleteHandler?: (id: string, index: number) => void;
  dispatch: (
    id: string,
    title: string,
    description: string,
    section_id: string,
    image_src: File,
    index: number
  ) => void
}

const SlideForm = ({
  id,
  isEditable,
  description,
  title,
  index,
  dispatch,
  closeWindowHandler,
  deleteHandler,
  formTitle,
  image_src,
  section_id,
  sectionData
}: Props) => {

  const [isReadonly, setIsReadonly] = useState<boolean>(!isEditable ? false : true);
  
  const titleRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const sectionRef = useRef<HTMLSelectElement>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);
  
  const toggleReadonly = () => {
    setIsReadonly(!isReadonly);
  }

  const submitFormHandler = (event: FormEvent) => {
    event.preventDefault();

    if (!imageInputRef.current!.files) {
      return;
    }

    const titleValue = titleRef.current!.value;
    const descriptionValue = descriptionRef.current!.value;
    const sectionValue = sectionRef.current!.value;
    const imageValue = imageInputRef.current!.files[0];

    dispatch(id, titleValue, descriptionValue, sectionValue, imageValue, index);

    if (isEditable) {
      toggleReadonly();
    }
  }
  
  const selectOptions = [] as Array<SelectInputOptions>;
  sectionData.forEach(((section, index) => {
    if (index < 5 && index !== 2) {
      const sectionOption = {
        title: section.title,
        value: section.id
      } as SelectInputOptions

      selectOptions.push(sectionOption);
    }
  }))

  const formClass = !isReadonly
    ? `${classes['slide-form']} ${classes['slide-form--edit']}`
    : `${classes['slide-form']}`;
  
  return (
    <form
      className={formClass}
      onSubmit={submitFormHandler}
    >
      <div className={classes['slide-form__toolbar']}>
        {deleteHandler && !isReadonly &&
          <DeleteButton
            onClick={deleteHandler.bind(this, id, index)}
          />}
        
        {isEditable &&
          <EditButton
            isReadonly={isReadonly}
            onClick={toggleReadonly}
          />}
        
        {!isEditable && <CloseButton
          onClick={closeWindowHandler ? closeWindowHandler : () => {}}
        />}
      </div>

      {formTitle && <h1>{formTitle}</h1>}

      <TextInput
        ref={titleRef}
        title='Title'
        value={title}
      />

      <TextAreaInput
        ref={descriptionRef}
        title='Description'
        value={description}
      />

      <SelectInput
        ref={sectionRef}
        options={selectOptions}
        title='Section'
        selectedValue={section_id}
      />
      
      {image_src && <div className={classes['page-data-form__image']}>
        <h1>Image:</h1>
        <img src={`${backendUrl}/${image_src}`} alt={image_src} />
      </div>}
      
      {!isReadonly && 
        <>
          <FileInput
            ref={imageInputRef}
            title={'Select Image'}
            isRequired={false}
            acceptedFile='image'
          />
          <MainButton
            title={isEditable ? 'EDIT' : 'CREATE'}
            isSubmit={true}
          />
        </>}
    </form>
  )
}

export default SlideForm