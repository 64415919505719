import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { SECTION_FAIL, SECTION_REQUEST, SECTION_SUCCESS } from "data/constants/store/section";
import { BACKEND_SECTION_ROUTE } from "data/routes/backendRoutes";
import { sectionActions } from "../section-slice";

export const editSection = (
  id: string,
  index: number,
  title: string,
  image?: File
) => async (dispatch: Dispatch) => {
  dispatch(sectionActions.edit({ type: SECTION_REQUEST }));

  const formData = new FormData();
  image && formData.append('fileToUpload', image, image.name);
  formData.append('title', title);
  formData.append('method', 'PATCH');
  formData.append('id', id);

  const editSectionRequest = async () => {
    return await axios.post(BACKEND_SECTION_ROUTE, formData, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });
  }

  try {
    const {data} = await editSectionRequest();

    dispatch(sectionActions.edit({
      type: SECTION_SUCCESS,
      payload: {
        data,
        index
      }
    }));
  } catch (error: any) {
    dispatch(sectionActions.edit({
      type: SECTION_FAIL,
      payload: error.response.data.message
    }))
  }
}