import { Ferry } from 'data/interfaces/Ferry';
import FerryElement from '../FerryElement/FerryElement';
import classes from './FerryList.module.scss';

interface Props {
  ferryData: Array<Ferry>
}

const FerryList = ({ ferryData }: Props) => {
  const renderData = ferryData.map(data => (
    <FerryElement
      key={data.title + Math.random()}
      id='0'
      hours={data.hours}
      title={data.title}
    />
  ))
  return (
    <ul className={classes['ferry-list']}>
      {renderData}
    </ul>
  )
}

export default FerryList