import classes from './CloseButton.module.scss';

type Props = {
  onClick: () => void;
}

const CloseButton = ({onClick}: Props) => {
  return (
    <button
      className={classes['close-button']}
      type='button'
      onClick={onClick}
    />
  )
}

export default CloseButton