import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { FERRY_FAIL, FERRY_REQUEST, FERRY_SUCCESS } from "data/constants/store/Ferry";
import { Ferry } from "data/interfaces/Ferry";
import { BACKEND_FERRY_ROUTE } from "data/routes/backendRoutes";
import { ferryActions } from "../ferry-slice";

export const deleteFerry = (ferryArray: Array<Ferry>, index: number) => async (dispatch: Dispatch) => {
  dispatch(ferryActions.delete({ type: FERRY_REQUEST }));

  const tempFerryArray = [] as Array<Ferry>;

  if (ferryArray.length > 0) {
    ferryArray.forEach(value => {
      tempFerryArray.push(value);
    })
    tempFerryArray.splice(index, 1);
  }

  const formData = new FormData();
  formData.append('allProms', JSON.stringify(tempFerryArray));
  formData.append('method', 'PUT');

  const deleteFerryRequest = async () => {
    return await axios.post(BACKEND_FERRY_ROUTE, formData, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });
  }

  try {
    await deleteFerryRequest();

    dispatch(ferryActions.delete({
      type: FERRY_SUCCESS,
      payload: index
    }));
  } catch (error: any) {
    dispatch(ferryActions.delete({
      type: FERRY_FAIL,
      payload: error.response.data.message
    }))
  }
}