import { createSlice } from "@reduxjs/toolkit";
import { PAGE_DATA_FAIL, PAGE_DATA_REQUEST, PAGE_DATA_SUCCESS } from "data/constants/store/pageData";
import { PageData } from "data/interfaces/PageData";

const pageDataSlice = createSlice({
  name: 'pageData',
  initialState: {
    loading: false,
    error: '',
    data: [] as Array<PageData>
  },
  reducers: {
    get(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case PAGE_DATA_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case PAGE_DATA_SUCCESS:
          state.loading = false;
          state.error = '';
          state.data = payload;
          break;
        case PAGE_DATA_FAIL:
          state.loading = false;
          state.error = payload;
          state.data = [];
          break;
      }
    },
    create(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case PAGE_DATA_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case PAGE_DATA_SUCCESS:
          state.loading = false;
          state.error = '';
          state.data.push(payload);
          break;
        case PAGE_DATA_FAIL:
          state.loading = false;
          state.error = payload;
          state.data = [];
          break;
      }
    },
    edit(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case PAGE_DATA_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case PAGE_DATA_SUCCESS:
          state.loading = false;
          state.error = '';
          state.data[payload.index] = payload.data;
          break;
        case PAGE_DATA_FAIL:
          state.loading = false;
          state.error = payload;
          state.data = [];
          break;
      }
    },
    delete(state, action) {
      const { type, payload } = action.payload;
      switch (type) {
        case PAGE_DATA_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case PAGE_DATA_SUCCESS:
          state.loading = false;
          state.error = '';
          if (payload !== undefined) {
            state.data = state.data.filter(pageData => pageData.id !== payload);
          }
          break;
        case PAGE_DATA_FAIL:
          state.loading = false;
          state.error = payload;
          state.data = [];
          break;
      }
    }
  }
})

export const pageDataActions = pageDataSlice.actions;
export default pageDataSlice;