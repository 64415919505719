import { createSlice } from "@reduxjs/toolkit";
import { SLIDE_FAIL, SLIDE_REQUEST, SLIDE_SUCCESS } from "data/constants/store/slide";
import { Slide } from "data/interfaces/Slide";

const slideSlice = createSlice({
  name: 'slide',
  initialState: {
    loading: false,
    error: '',
    data: [] as Array<Slide>
  },
  reducers: {
    create(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case SLIDE_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case SLIDE_SUCCESS:
          state.loading = false;
          state.error = '';
          break;
        case SLIDE_FAIL:
          state.loading = false;
          state.error = payload;
          break;
      }
    },
    edit(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case SLIDE_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case SLIDE_SUCCESS:
          state.loading = false;
          state.error = '';
          break;
        case SLIDE_FAIL:
          state.loading = false;
          state.error = payload;
          break;
      }
    },
    delete(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case SLIDE_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case SLIDE_SUCCESS:
          state.loading = false;
          state.error = '';
          break;
        case SLIDE_FAIL:
          state.loading = false;
          state.error = payload;
          break;
      }
    }
  }
})

export const slideActions = slideSlice.actions;
export default slideSlice;