import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { PAGE_DATA_FAIL, PAGE_DATA_REQUEST, PAGE_DATA_SUCCESS } from "data/constants/store/pageData";
import { BACKEND_PAGE_DATA_ROUTE } from "data/routes/backendRoutes";
import { pageDataActions } from "../page-data-slice";

export const editPageData = (
  id: string,
  sectionId: string,
  index: number,
  title: string,
  text: string,
  list?: Array<string>,
  map_url?: string,
  image?: File
) => async (dispatch: Dispatch) => {
  dispatch(pageDataActions.edit({ type: PAGE_DATA_REQUEST }));

  const formData = new FormData();
  image && formData.append('fileToUpload', image, image.name);
  formData.append('text', text);
  list && formData.append('list', JSON.stringify(list));
  map_url && formData.append('map_url', map_url);
  formData.append('section_id', sectionId);
  formData.append('title', title);
  formData.append('id', id);
  formData.append('method', 'PATCH');

  const editPageDataRequest = async () => {
    return await axios.post(BACKEND_PAGE_DATA_ROUTE, formData, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });
  }

  try {
    const { data } = await editPageDataRequest();
    
    if (data.list) {
      data.list = JSON.parse(data.list);
    }
    else {
      data.list = []
    }

    dispatch(pageDataActions.edit({
      type: PAGE_DATA_SUCCESS,
      payload: {
        data,
        index
      }
    }));
  } catch (error: any) {
    dispatch(pageDataActions.edit({
      type: PAGE_DATA_FAIL,
      payload: error.response.data.message
    }))
  }
}