export const CLIENT_LOGIN_ROUTE = '/login';
export const CLIENT_ADMIN_ROUTE = '/admin';
export const CLIENT_ADMIN_LOGO_ROUTE = '/admin/logo';
export const CLIENT_ADMIN_SECTION_ROUTE = '/admin/section';
export const CLIENT_ADMIN_PAGE_DATA_ROUTE = '/admin/page-data';
export const CLIENT_ADMIN_CONTACT_ROUTE = '/admin/contact';
export const CLIENT_ADMIN_MESSAGES_ROUTE = '/admin/messages';
export const CLIENT_ADMIN_FAQ_ROUTE = '/admin/FAQ';
export const CLIENT_ADMIN_PRICE_ROUTE = '/admin/price';
export const CLIENT_ADMIN_SLIDER_ROUTE = '/admin/slider';
export const CLIENT_ADMIN_FERRY_ROUTE = '/admin/ferry';

export const CLIENT_CONTACT_ROUTE = '/kontakt';
export const CLIENT_FAHRRAD_ROUTE = '/fahrradverleih';
export const CLIENT_REPARATUR_ROUTE = '/reparatur';
export const CLIENT_SERVICE_ROUTE = '/service';
export const CLIENT_FAQ_ROUTE = '/FAQ';