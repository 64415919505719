import { CLIENT_LOGIN_ROUTE } from 'data/routes/clientRoutes';
import { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from 'store';
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import classes from './AdminLayout.module.scss';
import { getUser } from 'store/authentication/actions/authentication-get-action';
import SpinnerFullscreen from 'components/spinner/SpinnerFullscreen/SpinnerFullscreen';

type Props = {
  children: ReactNode
}

const AdminLayout = ({ children }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch() as Dispatch<any>;

  const { loading, isAuthorized } = useSelector((state: RootState) => state.authentication);
  
  useEffect(() => {
    dispatch(getUser());
  }, [dispatch])

  useEffect(() => {
    if (!isAuthorized && !loading) {
      navigate(CLIENT_LOGIN_ROUTE);
    }
  }, [navigate, isAuthorized, loading])
  
  return (
    <main className={classes.content}>
      {!loading && children}
      <SpinnerFullscreen isLoading={loading} />
    </main>
  )
}

export default AdminLayout