import AdminHeader from 'components/header/AdminHeader/AdminHeader';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import classes from './Index.module.scss';
import { useEffect } from 'react';
import { getContact } from 'store/contact/actions/contact-get-request';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import SpinnerFullscreen from 'components/spinner/SpinnerFullscreen/SpinnerFullscreen';
import ContactDataFrom from './components/ContactDataFrom';

const Contact = () => {
  const dispatch = useDispatch() as Dispatch<any>;

  const { data, loading } = useSelector((state: RootState) => state.contact);

  useEffect(() => {
    dispatch(getContact());
  }, [dispatch])

  return (
    <AdminLayout>
      <AdminHeader />
      <section className={classes['contact-page']}>
        {loading && <SpinnerFullscreen isLoading={loading} />}
        {!loading &&
          <ContactDataFrom
          city={data.city}
          email={data.email}
          phone_number={data.phone_number}
          postal_code={data.postal_code}
          street={data.street}
          home_number={data.home_number}
          id={data.id}
          facebook={data.facebook}
          instagram={data.instagram}
          google_maps={data.google_maps}
          google_my_business={data.google_my_business}
          whatsapp={data.whatsapp}
          youtube={data.youtube}
          city2={data.city2}
          home_number2={data.home_number2}
          postal_code2={data.postal_code2}
          street2={data.street2}
          />}
      </section>  
    </AdminLayout>
  )
}

export default Contact