import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { FAQ_FAIL, FAQ_REQUEST, FAQ_SUCCESS } from "data/constants/store/FAQ";
import { BACKEND_FAQ_ROUTE } from "data/routes/backendRoutes";
import { faqActions } from "../faq-slice";

export const getFAQ = () => async (dispatch: Dispatch) => {
  dispatch(faqActions.get({ type: FAQ_REQUEST }));

  const getFAQRequest = async () => {
    return await axios.get(BACKEND_FAQ_ROUTE);
  }

  try {
    const {data} = await getFAQRequest();

    dispatch(faqActions.get({
      type: FAQ_SUCCESS,
      payload: data
    }));
  } catch (error: any) {
    dispatch(faqActions.get({
      type: FAQ_FAIL,
      payload: error.response.data.message
    }))
  }
}