import classes from './CreateSlide.module.scss';
import { CSSTransition } from 'react-transition-group';
import { useRef, useState } from 'react';
import MainButton from 'components/button/MainButton/MainButton';
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import SlideForm from '../SlideForm/SlideForm';
import { Section } from 'data/interfaces/Section';
import { createSlide } from 'store/slide/actions/slide-create-action';


interface Props {
  sectionData: Array<Section>;
  resetSection: () => void;
}

const CreateSlide = ({ sectionData, resetSection }: Props) => {
  const dispatch = useDispatch() as Dispatch<any>;
  
  const [isForm, setIsForm] = useState<boolean>(false);
  const [isButton, setIsButton] = useState<boolean>(true);

  const toggleForm = () => {
    const timeoutTime = 210;

    if (isButton) {
      setIsButton(!isButton)
      setTimeout(() => {
        setIsForm(!isForm);
      }, timeoutTime);
      return;
    }

    if (isForm) {
      setIsForm(!isForm);
      setTimeout(() => {
        setIsButton(!isButton);
      }, timeoutTime);
      return;
    }
  }

  const createSlideHandler = (
    id: string,
    title: string,
    description: string,
    section_id: string,
    image_src: File,
    index: number) => {
    dispatch(createSlide(section_id, image_src, title, description));
    toggleForm();
    resetSection();
  }

  const nodeRef = useRef(null);
  const nodeRef2 = useRef(null);
  return (
    <div className={classes['create-slide']}>
      <CSSTransition
        nodeRef={nodeRef}
        in={isButton}
        timeout={200}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: classes['enter'],
          enterActive: classes['enter-active'],
          exit: classes['exit'],
          exitActive: classes['exit-active']
        }}
      >
        <div
          className={classes['create-slide__button']}
          ref={nodeRef}
        >
          <MainButton
            title={'Create new Slide'}
            onClick={toggleForm}
          />
        </div>
      </CSSTransition>


      <CSSTransition
        nodeRef={nodeRef2}
        in={isForm}
        timeout={200}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: classes['enter'],
          enterActive: classes['enter-active'],
          exit: classes['exit'],
          exitActive: classes['exit-active']
        }}
      >
        <div
          className={classes['create-slide__form']}
          ref={nodeRef2}
        >
          <SlideForm
            description=''
            id=''
            image_src=''
            section_id='0'
            index={0}
            title=''
            isEditable={false}
            closeWindowHandler={toggleForm}
            formTitle='Create new slide'
            dispatch={createSlideHandler}
            sectionData={sectionData}
          />
        </div>
      </CSSTransition>
    </div>
  )
}

export default CreateSlide