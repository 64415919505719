import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { CONTACT_FAIL, CONTACT_REQUEST, CONTACT_SUCCESS } from "data/constants/store/contact";
import { BACKEND_CONTACT_ROUTE } from "data/routes/backendRoutes";
import { contactActions } from "../contact-slice";

export const editContact = (
  phoneNumber: string,
  email: string,
  street: string,
  city: string,
  postalCode: string,
  homeNumber: string,
  facebook: string,
  instagram: string,
  googleBuisness: string,
  whatsapp: string,
  youtube: string,
  city2: string,
  street2: string,
  postal_code2: string,
  home_number2: string
) => async (dispatch: Dispatch) => {
  dispatch(contactActions.edit({ type: CONTACT_REQUEST }));
  
  const formData = new FormData();
  formData.append('phone_number', phoneNumber);
  formData.append('email', email);
  formData.append('street', street);
  formData.append('city', city);
  formData.append('postal_code', postalCode);
  formData.append('home_number', homeNumber);
  formData.append('facebook', facebook);
  formData.append('instagram', instagram);
  formData.append('google_my_business', googleBuisness);
  formData.append('whatsapp', whatsapp);
  formData.append('youtube', youtube);

  formData.append('city2', city2);
  formData.append('street2', street2);
  formData.append('postal_code2', postal_code2);
  formData.append('home_number2', home_number2);
  
  formData.append('method', 'PATCH');

  const editContactRequest = async () => {
    return await axios.post(BACKEND_CONTACT_ROUTE, formData, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });
  }

  try {
    const {data} = await editContactRequest();

    dispatch(contactActions.get({
      type: CONTACT_SUCCESS,
      payload: data
    }));
  } catch (error: any) {
    dispatch(contactActions.get({
      type: CONTACT_FAIL,
      payload: error.response.data.message
    }))
  }
}