import HamburgerModal from 'components/modal/HamburgerModal/HamburgerModal';
import { useState } from 'react';
import classes from './HamburgerMenu.module.scss';

const HamburgerMenu = () => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const toggleActive = () => {
    setIsActive(!isActive)
  }

  const hamburgerButtonClass = isActive ? `${classes["hamburger-button"]} ${classes["hamburger-button--active"]}`:
    classes["hamburger-button"];
  
  return (
    <>
      <button onClick={toggleActive} className={hamburgerButtonClass}>
        <span className={classes["hamburger-button__box"]}>
            <span className={classes["hamburger-button__line"]}></span>
        </span>
      </button>
      <HamburgerModal
        close={toggleActive}
        isActive={isActive}
      />
    </>
  )
}

export default HamburgerMenu