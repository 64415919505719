import MainButton from 'components/button/MainButton/MainButton';
import { Section } from 'data/interfaces/Section';
import { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import PageDataForm from '../PageDataForm/PageDataForm';
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import classes from './CreatePageData.module.scss';
import { cratePageData } from 'store/pageData/actions/page-data-create-action';

interface Props {
  sectionData: Array<Section>;
  onDispatch: (sectionid: string) => void;
}

const CreatePageData = ({ sectionData, onDispatch }: Props) => {
  const dispatch = useDispatch() as Dispatch<any>;

  const [isForm, setIsForm] = useState<boolean>(false);
  const [isButton, setIsButton] = useState<boolean>(true);

  const toggleForm = () => {
    const timeoutTime = 210;

    if (isButton) {
      setIsButton(!isButton)
      setTimeout(() => {
        setIsForm(!isForm);
      }, timeoutTime);
      return;
    }

    if (isForm) {
      setIsForm(!isForm);
      setTimeout(() => {
        setIsButton(!isButton);
      }, timeoutTime);
      return;
    }
  }

  const createPageDataHandler = (
    index: number,
    id: string,
    sectionId: string,
    title: string,
    text: string,
    list?: Array<string>,
    googleMaps?: string,
    image?: File
  ) => {
    dispatch(cratePageData(sectionId, title, text, list, googleMaps, image));
    onDispatch(sectionId);
    toggleForm();
  }
  
  const nodeRef = useRef(null);
  const nodeRef2 = useRef(null);
  return (
    <div className={classes['create-page-data']}>
      <CSSTransition
        nodeRef={nodeRef}
        in={isButton}
        timeout={200}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: classes['enter'],
          enterActive: classes['enter-active'],
          exit: classes['exit'],
          exitActive: classes['exit-active']
        }}
      >
        <div
          className={classes['create-page-data__button']}
          ref={nodeRef}
        >
          <MainButton
            title={'Create new Page Data'}
            onClick={toggleForm}
          />
        </div>
      </CSSTransition>


      <CSSTransition
        nodeRef={nodeRef2}
        in={isForm}
        timeout={200}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: classes['enter'],
          enterActive: classes['enter-active'],
          exit: classes['exit'],
          exitActive: classes['exit-active']
        }}
      >
        <div
          className={classes['create-page-data__form']}
          ref={nodeRef2}
        >
          {sectionData.length > 0 && <PageDataForm
            index={0}
            id={'0'}
            title={''}
            text={''}
            image_src={''}
            section_id={sectionData[0].id}
            isEditable={false}
            formTitle={'Create new Page Data'}
            closeWindowHandler={toggleForm}
            sectionData={sectionData}
            dispatch={createPageDataHandler}
            list={[]}
          />}
        </div>
      </CSSTransition>
    </div>
  )
}

export default CreatePageData