import GoogleMaps from 'components/elements/GoogleMaps/GoogleMaps';
import { backendUrl } from 'data/routes/backendRoutes';
import classes from './ReparaturPageData.module.scss';

type Props = {
  title?: string;
  text?: string;
  image?: string;
  list?: Array<string>;
  isHover?: boolean;
  isLeft?: boolean;
  googleMaps?: string;
}

const ReparaturPageData = ({
  title,
  text,
  image,
  list,
  isHover,
  isLeft,
  googleMaps
}: Props) => {

  const renderList = list ? list.map(listElement => (
    <li
      className={classes['page-data__list-element']}
      key={listElement + Math.random()}
    >
      {listElement}
    </li>
  )) : [];

  const pageDataClass = isLeft
    ? `${classes['page-data']} ${classes['page-data--left']}`
    : `${classes['page-data']}`;
  
  return (
    <div className={pageDataClass} id={`${title}-${image}`}>
      {googleMaps && <div className={classes['page-data__map']}>
        <GoogleMaps link={googleMaps} />
      </div>}

      <div className={classes['page-data__data']}>
        {title && <h1 className={classes['page-data__title']}>{title}</h1>}
        {text && <p className={classes['page-data__text']}>{text}</p>}
        {list && <ul className={classes['page-data__list']}>
          {renderList}
        </ul>}
      </div>
      {image && <img
        className={classes['page-data__image']}
        src={`${backendUrl}${image}`}
        alt={`${title}`}
      />}
    </div>
  )
}

export default ReparaturPageData