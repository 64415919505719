import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { MESSAGE_FAIL, MESSAGE_REQUEST, MESSAGE_SUCCESS } from "data/constants/store/message";
import { BACKEND_MESSAGE_ROUTE } from "data/routes/backendRoutes";
import { messageActions } from "../messages-slice";

export const createMessage = (
  message: string,
  email?: string,
  phone?: string
) => async (dispatch: Dispatch) => {
  dispatch(messageActions.create({ type: MESSAGE_REQUEST }));

  const formData = new FormData();
  email && formData.append('email', email);
  phone && formData.append('phone', phone);
  formData.append('message', message);
  formData.append('method', 'POST');

  const createMessageRequest = async () => {
    return await axios.post(BACKEND_MESSAGE_ROUTE, formData, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });
  }

  try {
    await createMessageRequest();

    dispatch(messageActions.create({
      type: MESSAGE_SUCCESS
    }));
  } catch (error: any) {
    dispatch(messageActions.create({
      type: MESSAGE_FAIL,
      payload: error.response.data.message
    }))
  }
}