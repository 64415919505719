import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS } from "../../../data/constants/store/login";
import { BACKEND_LOGIN_ROUTE } from "../../../data/routes/backendRoutes";
import { authenticationActions } from "../authentication-slice";

export const loginUser = (
  login: string,
  password: string
) => async (dispatch: Dispatch) => {
  dispatch(authenticationActions.login({ type: LOGIN_REQUEST }));

  const loginRequest = async () => {
    return axios.post(BACKEND_LOGIN_ROUTE, { login, password }, { withCredentials: true })
  }

  try {
    const { data } = await loginRequest();
    
    dispatch(authenticationActions.login({
      type: LOGIN_SUCCESS,
      payload: data.is_authorized
    }));
    
  } catch (error: any) {
    dispatch(authenticationActions.login({
      type: LOGIN_FAIL,
      payload: error.message
    }))
  }
}