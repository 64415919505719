import { Dispatch } from '@reduxjs/toolkit';
import { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getLogo2 } from 'store/logo/actions/logo2-get-action';
import useDetectDevice from '../../hooks/useDetectDevice';
import { RootState } from '../../store';
import { getLogo } from '../../store/logo/actions/logo-get-action';

interface Props {
  children: ReactNode;
}

const MasterLayout = ({children}: Props) => {
  const dispatch = useDispatch() as Dispatch<any>;
  const logo = useSelector((state: RootState) => state.logo.logo);
  const logo2 = useSelector((state: RootState) => state.logo.logo2);

  useDetectDevice();

  useEffect(() => {
    //fetch logo from backend
    if (!logo) {
      dispatch(getLogo());
    }

    if (!logo2) {
      dispatch(getLogo2());
    }
  }, [dispatch, logo, logo2])
  
  
  return (
    <>
      {children}
    </>
  )
}

export default MasterLayout;