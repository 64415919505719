import DeleteButton from 'components/button/DeleteButton/DeleteButton';
import { Message } from 'data/interfaces/Message';
import MessageText from '../MessageText/MessageText';
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import classes from './MessageBox.module.scss';
import { deleteMessage } from 'store/messages/actions/message-delete-action';

interface Props extends Message {}

const MessageBox = ({
  id,
  message,
  email,
  phone
}: Props) => {
  const dispatch = useDispatch() as Dispatch<any>;

  const deleteMessageHandler = () => {
    dispatch(deleteMessage(id));
  }

  return (
    <div className={classes['message']}>
      <h1 className={classes['message__title']}>ID: <span>{ id }</span></h1>

      {email &&
        <MessageText
          title='Email'
          text={email}
          isCopy={true}
        />
      }

      {phone &&
        <MessageText
          title='Phone number'
          text={phone} 
          isCopy={true}
        />
      }

      <MessageText
        title='Message'
        text={message}
        isCopy={false}
      />

      <div className={classes['message__toolbar']}>
        <DeleteButton
          onClick={deleteMessageHandler}
        />
      </div>
    </div>
  )
}

export default MessageBox