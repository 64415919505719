import { CLIENT_ADMIN_CONTACT_ROUTE, CLIENT_ADMIN_FAQ_ROUTE, CLIENT_ADMIN_FERRY_ROUTE, CLIENT_ADMIN_LOGO_ROUTE, CLIENT_ADMIN_MESSAGES_ROUTE, CLIENT_ADMIN_PAGE_DATA_ROUTE, CLIENT_ADMIN_PRICE_ROUTE, CLIENT_ADMIN_ROUTE, CLIENT_ADMIN_SECTION_ROUTE, CLIENT_ADMIN_SLIDER_ROUTE, CLIENT_CONTACT_ROUTE, CLIENT_FAHRRAD_ROUTE, CLIENT_FAQ_ROUTE, CLIENT_LOGIN_ROUTE, CLIENT_REPARATUR_ROUTE, CLIENT_SERVICE_ROUTE } from "data/routes/clientRoutes";
import MasterLayout from "layouts/MasterLayout/MasterLayout";
import Admin from "pages/authentication/Admin/Index";
import Contact from "pages/authentication/Contact/Index";
import FAQ from "pages/authentication/FAQ/Index";
import Ferry from "pages/authentication/Ferry/Index";
import Login from "pages/authentication/Login";
import Logo from "pages/authentication/Logo/Index";
import Messages from "pages/authentication/Messages/Index";
import PageData from "pages/authentication/PageData/Index";
import Price from "pages/authentication/Price/Index";
import Section from "pages/authentication/Section/Index";
import Slider from "pages/authentication/Slider/Index";
import FahrradPage from "pages/FahrradPage/Index";
import FAQPage from "pages/FAQPage/Index";
import KontaktPage from "pages/KontaktPage/Index";
import MainPage from "pages/MainPage/Index";
import ReparaturPage from "pages/ReparaturPage/Index";
import ServicePage from "pages/ServicePage/Index";
import { Route, Routes } from "react-router";

const App = () => {
  return (
    <MasterLayout>
      <Routes>
        <Route path={CLIENT_LOGIN_ROUTE} element={<Login />} />
        <Route path={CLIENT_ADMIN_ROUTE} element={<Admin />} />
        <Route path={CLIENT_ADMIN_LOGO_ROUTE} element={<Logo />} />
        <Route path={CLIENT_ADMIN_SECTION_ROUTE} element={<Section />} />
        <Route path={CLIENT_ADMIN_PAGE_DATA_ROUTE} element={<PageData />} />
        <Route path={CLIENT_ADMIN_CONTACT_ROUTE} element={<Contact />} />
        <Route path={CLIENT_ADMIN_MESSAGES_ROUTE} element={<Messages />} />
        <Route path={CLIENT_ADMIN_FAQ_ROUTE} element={<FAQ />} />
        <Route path={CLIENT_ADMIN_PRICE_ROUTE} element={<Price />} />
        <Route path={CLIENT_ADMIN_SLIDER_ROUTE} element={<Slider />} />
        <Route path={CLIENT_ADMIN_FERRY_ROUTE} element={<Ferry />} />

        <Route path={'/'} element={<MainPage />} />
        <Route path={CLIENT_FAHRRAD_ROUTE} element={<FahrradPage />} />
        <Route path={CLIENT_REPARATUR_ROUTE} element={<ReparaturPage />} />
        <Route path={CLIENT_CONTACT_ROUTE} element={<KontaktPage />} />
        <Route path={CLIENT_SERVICE_ROUTE} element={<ServicePage />} />
        <Route path={CLIENT_FAQ_ROUTE} element={<FAQPage />} />
      </Routes>
    </MasterLayout>
  );
}

export default App;
