import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'store';
import classes from './LogoLink.module.scss';

type Props = {
  route: string;
}

const LogoLink = ({ route }: Props) => {
  const logoUrl = useSelector((state: RootState) => state.logo.logo);
  return (
    <Link
      to={route}
      className={classes.logo}
      style={{backgroundImage: `url(${logoUrl})`}}
    />
  )
}

export default LogoLink