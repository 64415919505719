import MainButton from 'components/button/MainButton/MainButton';
import PasswordInput from 'components/input/PasswordInput/PasswordInput';
import MainModal from 'components/modal/MainModal/MainModal';
import { FormEvent, useRef } from 'react';
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import classes from './UserModal.module.scss';
import { editUser } from 'store/authentication/actions/authentication-edit-action';

type Props = {
  isActive: boolean;
  close: () => void;
}

const UserModal = ({
  isActive,
  close
}: Props) => {
  const dispatch = useDispatch() as Dispatch<any>;

  const currentPasswordRef = useRef<HTMLInputElement>(null);
  const newPasswordRef = useRef<HTMLInputElement>(null);

  const changePasswordHandler = (event: FormEvent) => {
    event.preventDefault();

    const currentPasswordValue = currentPasswordRef.current!.value;
    const newPasswordValue = newPasswordRef.current!.value;

    if (!currentPasswordRef && !newPasswordRef)
      return;
    
    dispatch(editUser(currentPasswordValue, newPasswordValue));    
  }

  return (
    <MainModal
      activate={isActive}
      timeout={100}
      close={close}
    >
      <form
        className={classes['password-form']}
        onSubmit={changePasswordHandler}
      >
        <h1>Change Password</h1>
        <PasswordInput
          ref={currentPasswordRef}
          title={'Current Password'}
        />
        <PasswordInput
          ref={newPasswordRef}
          title={'New Password'}
        />
        <MainButton
          title='Change Password'
          isSubmit={true}
        />
      </form>
    </MainModal>
  )
}

export default UserModal