import PageDataMenu from 'components/elements/PageDataMenu/PageDataMenu';
import PageTitleElement from 'components/elements/PageTitleElement/PageTitleElement';
import SpinnerFullscreen from 'components/spinner/SpinnerFullscreen/SpinnerFullscreen';
import PageLayout from 'layouts/PageLayout/PageLayout';
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import classes from './Index.module.scss';
import { RootState } from 'store';
import { getPageData } from 'store/pageData/actions/page-data-get-action';
import { useEffect } from 'react';
import { Section } from 'data/interfaces/Section';
import ScrollTopButton from 'components/button/ScrollTopButton/ScrollTopButton';
import { getSlider } from 'store/slider/actions/get-slider-action';
import Slider from 'components/Slider/Slider';
import ReparaturPageData from './components/ReparaturPageData';
import FerryList from 'pages/KontaktPage/components/FerryList/FerryList';
import { getFerry } from 'store/ferry/actions/ferry-get-action';

const ReparaturPage = () => {
  const dispatch = useDispatch() as Dispatch<any>;

  const data = useSelector((state: RootState) => state.section.data);
  const reparaturData = data.length > 0 ? data[1] : { id: '', image_src: '', route: '', title: '' } as Section;

  const { data: pageData, loading } = useSelector((state: RootState) => state.pageData);
  const {loading: ferryLoading, data: ferryData} = useSelector((state: RootState) => state.ferry);
  const { loading: sliderLoading, data: sliderData } = useSelector((state: RootState) => state.slider);

  useEffect(() => {
    dispatch(getSlider('2'));
  }, [dispatch])
  
  useEffect(() => {
    dispatch(getPageData('2'));
  }, [dispatch, reparaturData.id])

  useEffect(() => {
    dispatch(getFerry());
  }, [dispatch])

  
  const renderPageData = pageData.map((data, index) => {
    if (index % 2 !== 0) {
      return (
        <ReparaturPageData
          key={data.id}
          image={data.image_src}
          title={data.title}
          text={data.text}
          list={data.list}
          isLeft={true}
          googleMaps={data.map_url}
        />
      )
    }

    return (
      <ReparaturPageData
        key={data.id}
        image={data.image_src}
        title={data.title}
        text={data.text}
        list={data.list}
        googleMaps={data.map_url}
      />
    )
  })

  return (
    <PageLayout>
      <section className={classes['reparatur-page']}>
        <PageTitleElement
          image={ reparaturData.image_src }
          title={ reparaturData.title }
        />
        {loading && <SpinnerFullscreen isLoading={loading} />}
  
        {!loading && <>
          <PageDataMenu pageData={pageData} />
          {!ferryLoading && ferryData.length > 0 && <FerryList ferryData={ferryData} />}
          {renderPageData}
        </>}

        {!sliderLoading && sliderData.length > 0 && sliderData[0].slides.length > 0 && <Slider
          slides={sliderData[0].slides}
          isBorderRadious={true}
        />}
        
        <ScrollTopButton />
      </section>
    </PageLayout>
  )
}

export default ReparaturPage