import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { SLIDE_FAIL, SLIDE_REQUEST, SLIDE_SUCCESS } from "data/constants/store/slide";
import { SLIDER_SUCCESS } from "data/constants/store/slider";
import { BACKEND_SLIDE_ROUTE } from "data/routes/backendRoutes";
import { sliderActions } from "store/slider/slider-slice";
import { slideActions } from "../slide-slide";

export const deleteSlide = (
  id: string,
  index: number
) => async (dispatch: Dispatch) => {
  dispatch(slideActions.delete({ type: SLIDE_REQUEST }));

  const data = {
    id,
    method: 'DELETE'
  }

  const deleteSlideRequest = async () => {
    return await axios.post(BACKEND_SLIDE_ROUTE, data, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });
  }


  try {
    let { data } = await deleteSlideRequest();

    if (data.constructor !== Array) {
      data = [data];
    }
    
    dispatch(sliderActions.get({
      type: SLIDER_SUCCESS,
      payload: data
    }));

    dispatch(slideActions.delete({
      type: SLIDE_SUCCESS,
      payload: index
    }));
  } catch (error: any) {
    dispatch(slideActions.delete({
      type: SLIDE_FAIL,
      payload: error.response.data.message
    }))
  }
}