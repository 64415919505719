import { createSlice } from "@reduxjs/toolkit";
import { LOGO_FAIL, LOGO_REQUEST, LOGO_SUCCESS } from "../../data/constants/store/logo";
import { backendUrl } from "../../data/routes/backendRoutes";

const logoSlice = createSlice({
  name: 'logo',
  initialState: {
    loading: false,
    error: '',
    logo: '' as string,
    logo2: '' as string
  },
  reducers: {
    get(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case LOGO_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case LOGO_SUCCESS:
          state.loading = false;
          state.error = '';
          state.logo = `${backendUrl}/${payload}`;
          break;
        case LOGO_FAIL:
          state.loading = false;
          state.error = payload;
          state.logo = '';
          break;
      }
    },
    edit(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case LOGO_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case LOGO_SUCCESS:
          state.loading = false;
          state.error = '';
          state.logo = `${backendUrl}/${payload}`;
          break;
        case LOGO_FAIL:
          state.loading = false;
          state.error = payload;
          state.logo = '';
          break;
      }
    },
    get2(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case LOGO_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case LOGO_SUCCESS:
          state.loading = false;
          state.error = '';
          state.logo2 = `${backendUrl}/${payload}`;
          break;
        case LOGO_FAIL:
          state.loading = false;
          state.error = payload;
          state.logo2 = '';
          break;
      }
    },
    edit2(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case LOGO_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case LOGO_SUCCESS:
          state.loading = false;
          state.error = '';
          state.logo2 = `${backendUrl}/${payload}`;
          break;
        case LOGO_FAIL:
          state.loading = false;
          state.error = payload;
          state.logo2 = '';
          break;
      }
    }
  }
});

export const logoActions = logoSlice.actions;
export default logoSlice;