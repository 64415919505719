import { useState } from 'react';
import classes from './UserButton.module.scss';
import UserModal from './UserModal';

const UserButton = () => {
  const [isModal, setIsModal] = useState<boolean>(false);

  const toggleUserModal = () => {
    setIsModal(!isModal);
  }

  return (
    <>
      <button
        className={classes.user}
        type='button'
        onClick={toggleUserModal}
      />
      <UserModal
        isActive={isModal}
        close={toggleUserModal}
      />
    </>
  )
}

export default UserButton