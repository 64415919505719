import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { MESSAGE_FAIL, MESSAGE_REQUEST, MESSAGE_SUCCESS } from "data/constants/store/message";
import { BACKEND_MESSAGE_ROUTE } from "data/routes/backendRoutes";
import { messageActions } from "../messages-slice";

export const deleteMessage = (id: string) => async (dispatch: Dispatch) => {
  dispatch(messageActions.delete({ type: MESSAGE_REQUEST }));

  const data = {
    id,
    method: 'DELETE'
  }

  const deleteMessageRequest = async () => {
    return await axios.post(BACKEND_MESSAGE_ROUTE, data, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });
  }

  try {
    await deleteMessageRequest();
    
    dispatch(messageActions.delete({
      type: MESSAGE_SUCCESS,
      payload: id
    }));
  } catch (error: any) {
    dispatch(messageActions.delete({
      type: MESSAGE_FAIL,
      payload: error.response.data.message
    }))
  }
}