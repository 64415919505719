import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { FAQ_FAIL, FAQ_REQUEST, FAQ_SUCCESS } from "data/constants/store/FAQ";
import { BACKEND_FAQ_ROUTE } from "data/routes/backendRoutes";
import { faqActions } from "../faq-slice";

export const deleteFAQ = (
  id: string,
  index: number
) => async (dispatch: Dispatch) => {
  dispatch(faqActions.delete({ type: FAQ_REQUEST }));

  const data = {
    id,
    method: 'DELETE'
  }

  const deleteFAQRequest = async () => {
    return await axios.post(BACKEND_FAQ_ROUTE, data, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });
  }


  try {
    await deleteFAQRequest();

    dispatch(faqActions.delete({
      type: FAQ_SUCCESS,
      payload: index
    }));
  } catch (error: any) {
    dispatch(faqActions.delete({
      type: FAQ_FAIL,
      payload: error.response.data.message
    }))
  }
}