import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import classes from './LogoImage.module.scss';

const LogoImage = () => {
  const logo = useSelector((state: RootState) => state.logo.logo);

  return (
    <img
      className={classes.logo}
      src={logo}
      alt={'logo'}
    />
  )
}

export default LogoImage