import { NavLink } from 'react-router-dom';
import classes from './DesktopNavLink.module.scss';

type Props = {
  title: string;
  route: string;
}

const DesktopNavLink = ({
  title,
  route
}: Props) => {
  return (
    <NavLink
      to={`/${route}`}
      className={(navData) => navData.isActive ? classes.active : classes.inactive}
    >
      {title}
    </NavLink>
  )
}

export default DesktopNavLink