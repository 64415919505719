import { useSelector } from 'react-redux';
import { RootState } from 'store';
import classes from './ContactData.module.scss';
import phoneIcon from 'assets/images/icon/black/phoneIcon.svg';
import emailIcon from 'assets/images/icon/black/emailIcon.svg';
import locationIcon from 'assets/images/icon/black/locationIcon.svg';
import facebookIcon from 'assets/images/icon/black/facebookIcon.svg';
import instagramIcon from 'assets/images/icon/black/instaIcon.svg';
import whatsappIcon from 'assets/images/icon/black/whatsappIcon.svg';
import youtubeIcon from 'assets/images/icon/black/youtubeIcon.svg';
import googlePlusIcon from 'assets/images/icon/black/googlePlusIcon.svg';
import ContactDataData from '../ContactDataData/ContactDataData';
import ContactDataSocial from '../ContactDataSocial/ContactDataSocial';


const ContactData = () => {
  const contactData = useSelector((state: RootState) => state.contact.data);

  const locationData = `${contactData.street} ${contactData.home_number}, ${contactData.postal_code} ${contactData.city}`;
  const locationData2 = `${contactData.street2} ${contactData.home_number2}, ${contactData.postal_code2} ${contactData.city2}`;
  return (
    <div className={classes['contact-data']}>
      <ContactDataData
        image={phoneIcon}
        text={contactData.phone_number}
      />
      <ContactDataData
        image={emailIcon}
        text={contactData.email}
      />
      <ContactDataData
        image={locationIcon}
        text={locationData}
      />
      <ContactDataData
        image={locationIcon}
        text={locationData2}
      />
      <div className={classes['contact-data__socials']}>
        {contactData.whatsapp && <ContactDataSocial
          image={whatsappIcon}
          link={contactData.whatsapp}
        />}
        {contactData.facebook && <ContactDataSocial
          image={facebookIcon}
          link={contactData.facebook}
        />}
        {contactData.instagram && <ContactDataSocial
          image={instagramIcon}
          link={contactData.instagram}
        />}
        {contactData.youtube && <ContactDataSocial
          image={youtubeIcon}
          link={contactData.youtube}
        />}
        {contactData.google_my_business && <ContactDataSocial
          image={googlePlusIcon}
          link={contactData.google_my_business}
        />}
      </div>
    </div>
  )
}

export default ContactData