import { backendUrl } from 'data/routes/backendRoutes';
import classes from './PageDataMenuElement.module.scss';

type Props = {
  title: string;
  image: string;
}

const PageDataMenuElement = ({
  title,
  image
}: Props) => {
  const handleClickScroll = () => {
    const element = document.getElementById(`${title}-${image}`);
    if (element) {
      const yOffset = -110; 
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({top: y, behavior: 'smooth'});
    }
  };

  return (
    <div className={classes['menu-element']} onClick={handleClickScroll}>
      <img src={`${backendUrl}${image}`} alt={`${title}-img`} className={classes['menu-element__image']} />
      <h3 className={classes['menu-element__title']}>{title}</h3>
    </div>
  )
}

export default PageDataMenuElement