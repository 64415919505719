import { backendUrl } from 'data/routes/backendRoutes';
import classes from './PageDataElement.module.scss';

type Props = {
  title?: string;
  text?: string;
  image?: string;
  list?: Array<string>;
  isHover?: boolean;
}

const PageDataElement = ({
  title,
  text,
  image,
  list,
  isHover
}: Props) => {

  const renderList = list ? list.map(listElement => (
    <li
      className={classes['page-data__list-element']}
      key={listElement + Math.random()}
    >
      {listElement}
    </li>
  )) : [];

  const pageDataClass = isHover 
    ? `${classes['page-data']} ${classes['page-data--hover']}`
    : `${classes['page-data']}`

  return (
    <div className={pageDataClass} id={`${title}-${image}`}>
      {title && <h1 className={classes['page-data__title']}>{title}</h1>}
      {text && <p className={classes['page-data__text']}>{text}</p>}
      {list && <ul className={classes['page-data__list']}>
        {renderList}
      </ul>}
      {image && <img
        className={classes['page-data__image']}
        src={`${backendUrl}${image}`}
        alt={`${title}`}
      />}
    </div>
  )
}

export default PageDataElement