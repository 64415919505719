import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { PRICE_FAIL, PRICE_REQUEST, PRICE_SUCCESS } from "data/constants/store/price";
import { BACKEND_SERVICE_ROUTE } from "data/routes/backendRoutes";
import { priceActions } from "../price-slice";


export const editPrice = (
  id: string,
  title: string,
  price: string,
  description: string,
  index: number
) => async (dispatch: Dispatch) => {
  dispatch(priceActions.edit({ type: PRICE_REQUEST }));

  const formData = new FormData();
  formData.append('id', id);
  formData.append('title', title);
  formData.append('price', price);
  formData.append('description', description);
  formData.append('method', 'PATCH');

  const editPriceRequest = async () => {
    return await axios.post(BACKEND_SERVICE_ROUTE, formData, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });
  }

  try {
    const {data} = await editPriceRequest();

    dispatch(priceActions.edit({
      type: PRICE_SUCCESS,
      payload: {
        data, 
        index
      }
    }));
  } catch (error: any) {
    dispatch(priceActions.edit({
      type: PRICE_FAIL,
      payload: error.response.data.message
    }))
  }
}