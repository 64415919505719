import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { PAGE_DATA_FAIL, PAGE_DATA_REQUEST, PAGE_DATA_SUCCESS } from "data/constants/store/pageData";
import { BACKEND_PAGE_DATA_ROUTE } from "data/routes/backendRoutes";
import { pageDataActions } from "../page-data-slice";

export const getPageData = (
  id?: string
) => async (dispatch: Dispatch) => {
  dispatch(pageDataActions.get({ type: PAGE_DATA_REQUEST }));

  const getPageDataRequest = async () => {
    if (id) {
      return await axios.get(BACKEND_PAGE_DATA_ROUTE, {params: {section_id: id}});
    }
    return await axios.get(BACKEND_PAGE_DATA_ROUTE);
  }

  try {
    const { data } = await getPageDataRequest();

    for (let i = 0; i < data.length; i++) {
      if (data[i].list) {
        data[i].list = JSON.parse(data[i].list);
        
      }
      else {
        data[i].list = []
      }
    }
    
    dispatch(pageDataActions.get({
      type: PAGE_DATA_SUCCESS,
      payload: data
    }));
  } catch (error: any) {
    dispatch(pageDataActions.get({
      type: PAGE_DATA_FAIL,
      payload: error.response.data.message
    }))
  }
}