import { ChangeEvent, forwardRef, useId, useRef, useState } from 'react';
import useAssignRefs from '../../../hooks/useAssignRefs';
import InputWrapper from '../InputWrapper/InputWrapper';

type Props = {
  title: string;
  isRequired?: boolean;
  value?: string;
  isReadonly?: boolean;
  index?: number,
  changeHandler?: (value: string, index?: number) => void;
}

const TextInput = forwardRef<HTMLInputElement, Props>(({
  title,
  isRequired = false,
  value = '',
  isReadonly,
  index,
  changeHandler
}, ref) => {
  const inputLocalRef = useRef<HTMLInputElement>(null);
  const inputRef = useAssignRefs(inputLocalRef, ref);

  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(value);

  const focusInput = () => {
    inputLocalRef.current?.focus();
    setFocus();
  }

  const setFocus = () => {
    setIsFocus(true);
  }

  const setBlur = () => {
    setIsFocus(false);
  }

  const inputValueHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    
    if (changeHandler && index?.toString()) {
      return changeHandler(newValue, index);
    }

    if (changeHandler) {
      return changeHandler(newValue);
    }
  }

  const inputId = useId();
  return (
    <InputWrapper
      focusInput={focusInput}
      isInputFocus={isFocus}
      title={title}
      inputId={inputId}
      isValid={true}
    >
      <input
        ref={inputRef}
        id={inputId}
        autoCapitalize='none'
        autoCorrect='off'
        spellCheck='false'
        onBlur={setBlur}
        required={isRequired}
        type='text'
        readOnly={isReadonly}
        value={inputValue}
        onChange={inputValueHandler}
      />
    </InputWrapper>
  )
})

export default TextInput