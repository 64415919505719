import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { PAGE_DATA_FAIL, PAGE_DATA_REQUEST, PAGE_DATA_SUCCESS } from "data/constants/store/pageData";
import { BACKEND_PAGE_DATA_ROUTE } from "data/routes/backendRoutes";
import { pageDataActions } from "../page-data-slice";

export const deletePageData = (id: string) => async (dispatch: Dispatch) => {
  dispatch(pageDataActions.delete({ type: PAGE_DATA_REQUEST }));

  const data = {
    id,
    method: 'DELETE'
  }

  const deletePageDataRequest = async () => {
    return await axios.post(BACKEND_PAGE_DATA_ROUTE, data, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });
  }

  try {
    await deletePageDataRequest();
    
    dispatch(pageDataActions.delete({
      type: PAGE_DATA_SUCCESS,
      payload: id
    }));
  } catch (error: any) {
    dispatch(pageDataActions.delete({
      type: PAGE_DATA_FAIL,
      payload: error.response.data.message
    }))
  }
}