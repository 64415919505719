import { useSelector } from 'react-redux';
import { RootState } from 'store';
import classes from './MainPageLogo.module.scss';

const MainPageLogo = () => {
  const logo = useSelector((state: RootState) => state.logo.logo);
  
  return (
    <div className={classes['logo']}>
      <img
        src={logo}
        alt='fhh-logo'
        className={classes['logo__image']}
      />
    </div>
  )
}

export default MainPageLogo