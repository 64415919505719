import { useSelector } from "react-redux";
import { RootState } from "store";
import PageLayout from "layouts/PageLayout/PageLayout";
import classes from './Index.module.scss';
import MainPageLogo from "./components/MainPageLogo/MainPageLogo";
import MainPageSection from "./components/MainPageSection/MainPageSection";

const MainPage = () => {
  const { data: sectionData } = useSelector((state: RootState) => state.section);
  
  const renderSections = sectionData.map(section => (
    <MainPageSection
      key={section.id}
      id={section.id}
      image_src={section.image_src}
      route={section.route}
      title={section.title}
    />
  ))

  return (
    <PageLayout>
      <section className={classes['main-page']}>
        <MainPageLogo />
        <div className={classes['main-page__sections']}>
          {renderSections}
        </div>
      </section>
    </PageLayout>
  )
}

export default MainPage