import classes from './MessageText.module.scss';
import {CopyToClipboard} from 'react-copy-to-clipboard';

type Props = {
  title: string;
  text: string;
  isCopy: boolean;
}

const MessageText = ({
  title,
  text,
  isCopy
}: Props) => {
  return (
    <div className={classes['message-text']}>
      <h4 className={classes['message-text__title']}>
        {title}
        {isCopy && <CopyToClipboard
          text={text}
        >
          <span
            className={classes['message-text__title-copy']}
            title={'Copy'}
          />
        </CopyToClipboard>}
      </h4>
      <p className={classes['message-text__text']}>
        {text}
      </p>
    </div>
  )
}

export default MessageText