import { useSelector } from 'react-redux';
import { RootState } from 'store';
import classes from './ScrollTopButton.module.scss';

const ScrollTopButton = () => {
  const isScrolled = useSelector((state: RootState) => state.client.isScrolled);
  
  const scrollTopHandle = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  
  return (
    <>
      {isScrolled && <button
        className={classes['scroll-top-button']}
        onClick={scrollTopHandle}
      />}
    </>
  )
}

export default ScrollTopButton