import AdminHeader from "components/header/AdminHeader/AdminHeader";
import { CLIENT_ADMIN_CONTACT_ROUTE, CLIENT_ADMIN_FAQ_ROUTE, CLIENT_ADMIN_FERRY_ROUTE, CLIENT_ADMIN_LOGO_ROUTE, CLIENT_ADMIN_MESSAGES_ROUTE, CLIENT_ADMIN_PAGE_DATA_ROUTE, CLIENT_ADMIN_PRICE_ROUTE, CLIENT_ADMIN_SECTION_ROUTE, CLIENT_ADMIN_SLIDER_ROUTE } from "data/routes/clientRoutes";
import AdminLayout from "layouts/AdminLayout/AdminLayout";
import AdminLink from "./components/AdminButton/AdminLink";
import classes from "./Index.module.scss";

const Admin = () => {
  return (
    <AdminLayout>
      <AdminHeader />
      <section className={classes['admin-page']}>
        <AdminLink
          title="Logo"
          route={CLIENT_ADMIN_LOGO_ROUTE}
        />
        <AdminLink
          title="Section"
          route={CLIENT_ADMIN_SECTION_ROUTE}
        />
        <AdminLink
          title="Page Data"
          route={CLIENT_ADMIN_PAGE_DATA_ROUTE}
        />
        <AdminLink
          title="Contact Data"
          route={CLIENT_ADMIN_CONTACT_ROUTE}
        />
        <AdminLink
          title="FAQ"
          route={CLIENT_ADMIN_FAQ_ROUTE}
        />
        <AdminLink
          title="Servies"
          route={CLIENT_ADMIN_PRICE_ROUTE}
          isBackgroundImg={true}
        />
        <AdminLink
          title="Slider"
          route={CLIENT_ADMIN_SLIDER_ROUTE}
          isBackgroundImg={true}
        />
        <AdminLink
          title="Ferry"
          route={CLIENT_ADMIN_FERRY_ROUTE}
          isBackgroundImg={true}
        />
        <AdminLink
          title="Messages"
          route={CLIENT_ADMIN_MESSAGES_ROUTE}
          isBackgroundImg={false}
        />
      </section>  
    </AdminLayout>
  )
}

export default Admin;