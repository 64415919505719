import classes from './FAQCreate.module.scss';
import { CSSTransition } from 'react-transition-group';
import { useRef, useState } from 'react';
import MainButton from 'components/button/MainButton/MainButton';
import FAQForm from '../FAQForm/FAQForm';
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { createFAQ } from 'store/FAQ/actions/faq-create-actions';

const FAQCreate = () => {
  const dispatch = useDispatch() as Dispatch<any>;


  const [isForm, setIsForm] = useState<boolean>(false);
  const [isButton, setIsButton] = useState<boolean>(true);

  const toggleForm = () => {
    const timeoutTime = 210;

    if (isButton) {
      setIsButton(!isButton)
      setTimeout(() => {
        setIsForm(!isForm);
      }, timeoutTime);
      return;
    }

    if (isForm) {
      setIsForm(!isForm);
      setTimeout(() => {
        setIsButton(!isButton);
      }, timeoutTime);
      return;
    }
  }

  const createNewFAQ = (id: string, title: string, description: string, index: number) => {
    console.log(description);
    toggleForm();
    dispatch(createFAQ(title, description));
  }

  const nodeRef = useRef(null);
  const nodeRef2 = useRef(null);
  return (
    <div className={classes['create-faq']}>
      <CSSTransition
        nodeRef={nodeRef}
        in={isButton}
        timeout={200}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: classes['enter'],
          enterActive: classes['enter-active'],
          exit: classes['exit'],
          exitActive: classes['exit-active']
        }}
      >
        <div
          className={classes['create-faq__button']}
          ref={nodeRef}
        >
          <MainButton
            title={'Create new FAQ'}
            onClick={toggleForm}
          />
        </div>
      </CSSTransition>


      <CSSTransition
        nodeRef={nodeRef2}
        in={isForm}
        timeout={200}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: classes['enter'],
          enterActive: classes['enter-active'],
          exit: classes['exit'],
          exitActive: classes['exit-active']
        }}
      >
        <div
          className={classes['create-faq__form']}
          ref={nodeRef2}
        >
          <FAQForm
            id="kek"
            isEditable={false}
            closeWindowHandler={toggleForm}
            formTitle={"Create new FAQ"}
            description={''}
            title={''}
            dispatch={createNewFAQ}
            index={0}
          />
        </div>
      </CSSTransition>
    </div>
  )
}

export default FAQCreate