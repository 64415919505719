import classes from './DeleteButton.module.scss';

interface Props {
  onClick: () => void;
}

const DeleteButton = ({
  onClick
}: Props) => {
  return (
    <button
      className={classes['delete-button']}
      type='button'
      onClick={onClick}
    />
  )
}

export default DeleteButton