import { Ferry } from 'data/interfaces/Ferry';
import classes from './FerryElement.module.scss';

interface Props extends Ferry {}

const FerryElement = ({
  title,
  hours
}: Props) => {

  const renderHours = hours.map(hour => (
    <li
      key={hour + Math.random()}
      className={classes['ferry-element__hours-hour']}
    >
      {hour}
    </li>
  ))

  return (
    <li className={classes['ferry-element']}>
      <h3 className={classes['ferry-element__title']}>{title}</h3>
      <ul className={classes['ferry-element__hours']}>{ renderHours }</ul>
    </li>
  )
}

export default FerryElement