import { backendUrl } from 'data/routes/backendRoutes';
import classes from './PageTitleElement.module.scss';

type Props = {
  image: string;
  title: string;
}

const PageTitleElement = ({
  image,
  title
}: Props) => {
  return (
    <div
      className={classes['title']}
      style={{
        backgroundImage: `url(${backendUrl}${image})`
      }}
    >
      <h1
        className={classes['title__title']}
      >
        {title}
      </h1>
    </div>
  )
}

export default PageTitleElement