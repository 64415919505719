export const backendUrl = 'https://amhauslowe.com';

export const BACKEND_LOGO_ROUTE = `${backendUrl}/api/logo`;

export const BACKEND_LOGO2_ROUTE = `${backendUrl}/api/logo2`;

export const BACKEND_LOGIN_ROUTE = `${backendUrl}/api/login`;

export const BACKEND_LOGOUT_ROUTE = `${backendUrl}/api/logout`;

export const BACKEND_SECTION_ROUTE = `${backendUrl}/api/section`;

export const BACKEND_PAGE_DATA_ROUTE = `${backendUrl}/api/page`;

export const BACKEND_CONTACT_ROUTE = `${backendUrl}/api/contact`;

export const BACKEND_MESSAGE_ROUTE = `${backendUrl}/api/contactForm`;

export const BACKEND_FAQ_ROUTE = `${backendUrl}/api/faq`;

export const BACKEND_SERVICE_ROUTE = `${backendUrl}/api/service`;

export const BACKEND_SLIDER_ROUTE = `${backendUrl}/api/slider`;

export const BACKEND_SLIDE_ROUTE = `${backendUrl}/api/slide`;

export const BACKEND_FERRY_ROUTE = `${backendUrl}/api/proms`;