import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { PRICE_FAIL, PRICE_REQUEST, PRICE_SUCCESS } from "data/constants/store/price";
import { BACKEND_SERVICE_ROUTE } from "data/routes/backendRoutes";
import { priceActions } from "../price-slice";

export const getPrice = () => async (dispatch: Dispatch) => {
  dispatch(priceActions.get({ type: PRICE_REQUEST }));

  const getFAQRequest = async () => {
    return await axios.get(BACKEND_SERVICE_ROUTE);
  }

  try {
    const {data} = await getFAQRequest();

    dispatch(priceActions.get({
      type: PRICE_SUCCESS,
      payload: data
    }));
  } catch (error: any) {
    dispatch(priceActions.get({
      type: PRICE_FAIL,
      payload: error.response.data.message
    }))
  }
}