import { createSlice } from "@reduxjs/toolkit";
import { MOBILE_WIDTH, SCROLL_VALUE } from "../../data/constants/client/dimensions";

const clientSlice = createSlice({
  name: 'client',
  initialState: {
    isMobile: false,
    isScrolled: false
  },
  reducers: {
    setMobile(state, action) {
      state.isMobile = action.payload <= MOBILE_WIDTH;
    },
    setScroll(state, action) {
      state.isScrolled = action.payload >= SCROLL_VALUE;
    }
  }
});

export const clientActions = clientSlice.actions;
export default clientSlice;