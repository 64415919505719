import MainButton from 'components/button/MainButton/MainButton';
import TextAreaInput from 'components/input/TextAreaInput/TextAreaInput';
import TextInput from 'components/input/TextInput/TextInput';
import { FormEvent, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import classes from './ContactForm.module.scss';
import { createMessage } from 'store/messages/actions/message-create-action';
import okIcon from 'assets/images/icon/black/okIcon.svg';

const ContactForm = () => {
  const dispatch = useDispatch() as Dispatch<any>;

  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);

  const [isMessage, setIsMessage] = useState<boolean>(false);

  const createMessageHandler = (event: FormEvent) => {
    event.preventDefault();

    const emailValue = emailRef.current!.value;
    const phoneValue = phoneRef.current!.value;
    const messageValue = messageRef.current!.value;

    if (!messageValue) {
      messageRef.current?.focus();
      return;
    }

    dispatch(createMessage(messageValue, emailValue, phoneValue));

    setIsMessage(true);
  }

  return (
    <form
      className={classes['contact-form']}
      onSubmit={createMessageHandler}
    >
      <h1 className={classes['contact-form__contact']}>Kontakt</h1>
      {!isMessage && <>
        <div className={classes['contact-form__data']}>
          <TextInput
            ref={emailRef}
            title='Email'
          />

          <TextInput
            ref={phoneRef}
            title='Phone'
          />
        </div>

        <TextAreaInput
          ref={messageRef}
          title='Message'
          isRequired={true}
        />

        <div className={classes['contact-form__button']}>
          <MainButton
            title='Send'
            isSubmit={true}
          />
        </div>
      </>}

      {isMessage &&
        <div className={classes['contact-form__delivered']}>
          <img src={okIcon} alt='ok' />
          <span>Danke!</span>
        </div>
      }
    </form>
  )
}

export default ContactForm