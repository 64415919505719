import EditButton from 'components/button/EditButton/EditButton';
import MainButton from 'components/button/MainButton/MainButton';
import FileInput from 'components/input/FileInput/FileInput';
import TextInput from 'components/input/TextInput/TextInput';
import { Section } from 'data/interfaces/Section';
import { backendUrl } from 'data/routes/backendRoutes';
import { FormEvent, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import classes from './SectionForm.module.scss';
import { editSection } from 'store/section/actions/section-edit-action';

interface Props extends Section {
  index: number;
}

const SectionForm = ({
  id,
  image_src,
  route,
  title,
  index
}: Props) => {
  const dispatch = useDispatch() as Dispatch<any>;

  const titleRef = useRef<HTMLInputElement>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);

  const [isReadonly, setIsReadonly] = useState<boolean>(true);

  const toggleReadonly = () => {
    setIsReadonly(!isReadonly);
  }

  const editSectionHandler = (event: FormEvent) => {
    event.preventDefault();

    if (!imageInputRef.current!.files) {
      return;
    }
    
    const titleValue = titleRef.current!.value;
    const imageValue = imageInputRef.current!.files[0];

    if (!imageValue) {
      dispatch(editSection(id, index, titleValue));
      return;
    }

    dispatch(editSection(id, index, titleValue, imageValue));
  }

  const sectionClass = isReadonly
    ? `${classes['section']}`
    : `${classes['section']} ${classes['section--active']}`;

  return (
    <form
      className={sectionClass}
      onSubmit={editSectionHandler}
    >
      <div className={classes['section__toolbar']}>
        <EditButton
          isReadonly={isReadonly}
          onClick={toggleReadonly}
        />
      </div>
      <TextInput
        ref={titleRef}
        title={'Title'}
        value={title}
        isReadonly={isReadonly}
      />
      <div className={classes['section__image']}>
        <h1>Image:</h1>
        <img src={`${backendUrl}/${image_src}`} alt={image_src} />
      </div>

      {!isReadonly && <>
        <FileInput
          ref={imageInputRef}
          title={'Select Image'}
          isRequired={false}
          acceptedFile='image'
        />
        <MainButton
          title={'EDIT'}
          isSubmit={true}
        />
      </>}
    </form>
  )
}

export default SectionForm