import { configureStore } from "@reduxjs/toolkit";
import authenticationSlice from "./authentication/authentication-slice";
import clientSlice from "./client/client-slice";
import contactSlice from "./contact/contact-slice";
import faqSlice from "./FAQ/faq-slice";
import ferrySlice from "./ferry/ferry-slice";
import logoSlice from "./logo/logo-slice";
import messageSlice from "./messages/messages-slice";
import pageDataSlice from "./pageData/page-data-slice";
import priceSlice from "./price/price-slice";
import sectionSlice from "./section/section-slice";
import slideSlice from "./slide/slide-slide";
import sliderSlice from "./slider/slider-slice";
const store = configureStore({
  reducer: {
    client: clientSlice.reducer,
    logo: logoSlice.reducer,
    authentication: authenticationSlice.reducer,
    section: sectionSlice.reducer,
    pageData: pageDataSlice.reducer,
    contact: contactSlice.reducer,
    message: messageSlice.reducer,
    faq: faqSlice.reducer,
    price: priceSlice.reducer,
    slider: sliderSlice.reducer,
    slide: slideSlice.reducer,
    ferry: ferrySlice.reducer
  }
});

export default store;
export type RootState = ReturnType<typeof store.getState>;