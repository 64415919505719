import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { FERRY_FAIL, FERRY_REQUEST, FERRY_SUCCESS } from "data/constants/store/Ferry";
import { BACKEND_FERRY_ROUTE } from "data/routes/backendRoutes";
import { ferryActions } from "../ferry-slice";

export const getFerry = () => async (dispatch: Dispatch) => {
  dispatch(ferryActions.get({ type: FERRY_REQUEST }));

  const getPageDataRequest = async () => {
    return await axios.get(BACKEND_FERRY_ROUTE);
  }

  try {
    let { data } = await getPageDataRequest();
    
    if (data === null)
      data = [];

    dispatch(ferryActions.get({
      type: FERRY_SUCCESS,
      payload: data
    }));
  } catch (error: any) {
    dispatch(ferryActions.get({
      type: FERRY_FAIL,
      payload: error.response.data.message
    }))
  }
}