import classes from './LogoutButton.module.scss';
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { logoutUser } from 'store/authentication/actions/authentication-logout-action';

const LogoutButton = () => {
  const dispatch = useDispatch() as Dispatch<any>;
  const logoutUserHandler = () => {
    dispatch(logoutUser());
  }

  return (
    <button
      className={classes.logout}
      type='button'
      onClick={logoutUserHandler}
    />
  )
}

export default LogoutButton