import AdminHeader from 'components/header/AdminHeader/AdminHeader';
import LogoImage from 'components/image/LogoImage/LogoImage';
import FileInput from 'components/input/FileInput/FileInput';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import { FormEvent, useRef } from 'react';
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import classes from './Index.module.scss';
import { editLogo } from 'store/logo/actions/logo-edit-action';
import MainButton from 'components/button/MainButton/MainButton';
import LogoImage2 from 'components/image/LogoImage2/LogoImage2';
import { editLogo2 } from 'store/logo/actions/logo2-edit-action';

const Logo = () => {
  const dispatch = useDispatch() as Dispatch<any>;
  const logoInputRef = useRef<HTMLInputElement>(null);
  const logo2InputRef = useRef<HTMLInputElement>(null);

  const changeImageHandler = (event: FormEvent) => {
    event.preventDefault();

    if (!logoInputRef.current!.files)
      return;
    
    const logoValue = logoInputRef.current!.files[0];

    dispatch(editLogo(logoValue));
  }

  const changeImage2Handler = (event: FormEvent) => {
    event.preventDefault();

    if (!logo2InputRef.current!.files)
      return;
    
    const logo2Value = logo2InputRef.current!.files[0];

    dispatch(editLogo2(logo2Value))
  }
  
  return (
    <AdminLayout>
      <AdminHeader />
      <section className={classes['logo-page']}>
        <div className={classes['logo-page__logo']}>
          <h1>Current Logo 1</h1>
          <div className={classes['logo-page__logo-logo']}>
            <LogoImage />
          </div>
        </div>
        <form
          className={classes['logo-page__form']}
          onSubmit={changeImageHandler}
        >
          <h1>Change Logo 1</h1>
          <FileInput
            ref={logoInputRef}
            title={'Select Image'}
            isRequired={true}
            acceptedFile={'image'}
          />
          <MainButton
            title={'UPLOAD'}
            isSubmit={true}
          />
        </form>

        <div className={classes['logo-page__logo']}>
          <h1>Current Logo 2</h1>
          <div className={classes['logo-page__logo-logo']}>
            <LogoImage2 />
          </div>
        </div>
        <form
          className={classes['logo-page__form']}
          onSubmit={changeImage2Handler}
        >
          <h1>Change Logo 2</h1>
          <FileInput
            ref={logo2InputRef}
            title={'Select Image'}
            isRequired={true}
            acceptedFile={'image'}
          />
          <MainButton
            title={'UPLOAD'}
            isSubmit={true}
          />
        </form>
      </section>  
    </AdminLayout>
  )
}

export default Logo