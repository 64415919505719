import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { FERRY_FAIL, FERRY_REQUEST, FERRY_SUCCESS } from "data/constants/store/Ferry";
import { Ferry } from "data/interfaces/Ferry";
import { BACKEND_FERRY_ROUTE } from "data/routes/backendRoutes";
import { ferryActions } from "../ferry-slice";

export const createFerry = (
  ferryArray: Array<Ferry>,
  title: string,
  hours: Array<string>
) => async (dispatch: Dispatch) => {
  dispatch(ferryActions.create({ type: FERRY_REQUEST }));

  const tempFerryArray = [];

  if (ferryArray.length > 0) {
    ferryArray.forEach(value => {
      tempFerryArray.push(value);
    })
  }
  
  const newFerry = {
    title,
    hours
  } as Ferry;
  
  tempFerryArray.push(newFerry);

  const formData = new FormData();
  formData.append('allProms', JSON.stringify(tempFerryArray));
  formData.append('method', 'PUT');

  const createFerryRequest = async () => {
    return await axios.post(BACKEND_FERRY_ROUTE, formData, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });
  }

  try {
    const { data } = await createFerryRequest();
    dispatch(ferryActions.create({
      type: FERRY_SUCCESS,
      payload: data
    }));
  } catch (error: any) {
    dispatch(ferryActions.create({
      type: FERRY_FAIL,
      payload: error.response.data.message
    }))
  }
}