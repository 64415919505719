import AdminHeader from 'components/header/AdminHeader/AdminHeader';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import classes from './Index.module.scss';
import { RootState } from 'store';
import { useEffect } from 'react';
import SpinnerFullscreen from 'components/spinner/SpinnerFullscreen/SpinnerFullscreen';
import PriceForm from './components/PriceForm/PriceForm';
import { getPrice } from 'store/price/actions/price-get-action';
import PriceCreate from './components/CreatePrice/CreatePrice';
import { editPrice } from 'store/price/actions/price-edit-action';
import { deletePrice } from 'store/price/actions/price-delete-action';

const Price = () => {
  const dispatch = useDispatch() as Dispatch<any>;

  const { loading, data } = useSelector((state: RootState) => state.price);

  useEffect(() => {
    dispatch(getPrice());
  }, [dispatch])

  const editPriceHandler = (id: string, title: string, price: string, description: string, index: number) => {
    dispatch(editPrice(id, title, price, description, index));
  }

  const deletePriceHandler = (id: string, index: number) => {
    dispatch(deletePrice(id, index));
  }

  const renderData = data.map((price, index)=> (
    <PriceForm
      key={price.id}
      id={price.id}
      title={price.title}
      description={price.description}
      isEditable={true}
      dispatch={editPriceHandler}
      deleteHandler={deletePriceHandler}
      index={index}
      price={price.price}
    />
  ))

  return (
    <AdminLayout>
      <AdminHeader />
      <section className={classes['price-page']}>
        {loading && <SpinnerFullscreen isLoading={loading} />}
        <PriceCreate />
        {data.length <= 0 && <h1 className={classes['price-page__info']}>No service found</h1>}
        {data.length > 0 && renderData}
      </section>  
    </AdminLayout>
  )
}

export default Price