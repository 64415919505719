import { Link } from 'react-router-dom';
import classes from './HamburgerModalNavLink.module.scss';

interface Props {
  title: string;
  route: string;
}

const HamburgerModalNavLink = ({
  route,
  title
}: Props) => {
  return (
    <Link
      to={`/${route}`}
      className={classes['hamburger-nav-link']}
    >
      {title}
    </Link>
  )
}

export default HamburgerModalNavLink