import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { LOGO_FAIL, LOGO_REQUEST, LOGO_SUCCESS } from "../../../data/constants/store/logo";
import { BACKEND_LOGO2_ROUTE } from "../../../data/routes/backendRoutes";
import { logoActions } from "../logo-slice";

export const getLogo2 = () => async (dispatch: Dispatch) => {
  dispatch(logoActions.get2({ type: LOGO_REQUEST }));

  const getLogoRequest = async () => {
    return await axios.get(BACKEND_LOGO2_ROUTE);
  }

  try {
    const {data} = await getLogoRequest();

    dispatch(logoActions.get2({
      type: LOGO_SUCCESS,
      payload: data.logo
    }));
  } catch (error: any) {
    dispatch(logoActions.get2({
      type: LOGO_FAIL,
      payload: error.response.data.message
    }))
  }
}