import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import classes from './LogoImage2.module.scss';

const LogoImage2 = () => {
  const logo = useSelector((state: RootState) => state.logo.logo2);

  return (
    <img
      className={classes.logo}
      src={logo}
      alt={'logo'}
    />
  )
}

export default LogoImage2