import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { SLIDE_FAIL, SLIDE_REQUEST, SLIDE_SUCCESS } from "data/constants/store/slide";
import { SLIDER_SUCCESS } from "data/constants/store/slider";
import { BACKEND_SLIDE_ROUTE } from "data/routes/backendRoutes";
import { sliderActions } from "store/slider/slider-slice";
import { slideActions } from "../slide-slide";

export const editSlide = (
  index: number,
  id: string,
  section_id: string,
  title: string,
  description: string,
  fileToUpload?: File,
) => async (dispatch: Dispatch) => {
  dispatch(slideActions.edit({ type: SLIDE_REQUEST }));

  const formData = new FormData();
  formData.append('id', id);
  formData.append('section_id', section_id);
  fileToUpload && formData.append('fileToUpload', fileToUpload, fileToUpload.name);
  formData.append('title', title);
  formData.append('description', description);
  formData.append('method', 'PATCH');

  const createSlideRequest = async () => {
    return await axios.post(BACKEND_SLIDE_ROUTE, formData, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });
  }

  try {
    let {data} = await createSlideRequest();

    if (data.constructor !== Array) {
      data = [data];
    }
    
    dispatch(sliderActions.get({
      type: SLIDER_SUCCESS,
      payload: data
    }));
    
    dispatch(slideActions.edit({
      type: SLIDE_SUCCESS,
      payload: data
    }));
  } catch (error: any) {
    dispatch(slideActions.edit({
      type: SLIDE_FAIL,
      payload: error.response.data.message
    }))
  }
}