import { createSlice } from "@reduxjs/toolkit";
import { PRICE_FAIL, PRICE_REQUEST, PRICE_SUCCESS } from "data/constants/store/price";
import { Price } from "data/interfaces/Price";

const priceSlice = createSlice({
  name: "price",
  initialState: {
    loading: false, 
    error: '',
    data: [] as Array<Price>
  },
  reducers: {
    get(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case PRICE_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case PRICE_SUCCESS:
          state.loading = false;
          state.error = '';
          state.data = payload;
          break;
        case PRICE_FAIL:
          state.loading = false;
          state.error = payload;
          break;
      }
    },
    create(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case PRICE_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case PRICE_SUCCESS:
          state.loading = false;
          state.error = '';
          state.data.push(payload);
          break;
        case PRICE_FAIL:
          state.loading = false;
          state.error = payload;
          break;
      }
    },
    edit(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case PRICE_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case PRICE_SUCCESS:
          state.loading = false;
          state.error = '';
          state.data[payload.index] = payload.data;
          break;
        case PRICE_FAIL:
          state.loading = false;
          state.error = payload;
          break;
      }
    },
    delete(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case PRICE_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case PRICE_SUCCESS:
          state.loading = false;
          state.error = '';
          state.data.splice(payload, 1);
          break;
        case PRICE_FAIL:
          state.loading = false;
          state.error = payload;
          break;
      }
    }
  }
})

export const priceActions = priceSlice.actions;
export default priceSlice;