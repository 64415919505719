import { Slide } from 'data/interfaces/Slide';
import { backendUrl } from 'data/routes/backendRoutes';
import { useEffect, useState } from 'react';
import classes from './Slider.module.scss';

type Props = {
  slides: Array<Slide>;
  isBorderRadious: boolean;
}

const Slider = ({
  slides,
  isBorderRadious
}: Props) => {

  const [activeSlide, setActiveSlide] = useState<number>(0);

  useEffect(() => {
    const switchSlideTimer = setTimeout(() => {
      if (activeSlide + 1 >= slides.length) {
        return setActiveSlide(0)
      }
      setActiveSlide(activeSlide + 1);
    }, 10000)
  
    return () => {
      clearTimeout(switchSlideTimer);
    }
  }, [activeSlide, slides.length])
  
  
  const sliderClass = isBorderRadious
    ? `${classes['slider']} ${classes['slider-asd']}`
    : `${classes['slider']}`;

  return (
    <div className={sliderClass}>
      {slides[activeSlide].title && <h2 className={classes['slider__title']}>
        {slides[activeSlide].title}
      </h2>}
      <div className={classes['slider__image']}>
        <img
          className={classes['slider__image-image']}
          src={`${backendUrl}/${slides[activeSlide].image_src}`}
          alt={slides[activeSlide].title}
        />
      </div>
      {slides[activeSlide].description && <p className={classes['slider__description']}>
        {slides[activeSlide].description}
      </p>}
    </div>
  )
}

export default Slider