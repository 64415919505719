import { ReactNode, useEffect } from 'react';
import classes from './PageLayout.module.scss';
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { getSections } from 'store/section/actions/section-get-action';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import SpinnerFullscreen from 'components/spinner/SpinnerFullscreen/SpinnerFullscreen';
import useDetectScroll from 'hooks/useDetectScroll';
import Header from 'components/header/Header/Header';
import Footer from 'components/footer/Footer/Footer';
import { getContact } from 'store/contact/actions/contact-get-request';

type Props = {
  children: ReactNode
}

const PageLayout = ({ children }: Props) => {
  const dispatch = useDispatch() as Dispatch<any>;
  useDetectScroll();

  const { loading: sectionLoading } = useSelector((state: RootState) => state.section);
  const { loading: contactLoading } = useSelector((state: RootState) => state.contact);

  useEffect(() => {
    dispatch(getSections());
    dispatch(getContact());
  }, [dispatch])

  return (
    <main className={classes.content}>
      {!sectionLoading && !contactLoading  && <>
        <Header />
        {children}
        <Footer />
      </>}
      <SpinnerFullscreen isLoading={sectionLoading || contactLoading} />
    </main>
  )
}

export default PageLayout