import { Dispatch } from '@reduxjs/toolkit';
import HamburgerMenu from 'components/HamburgerMenu/HamburgerMenu';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { clientSetMobile } from 'store/client/actions/set-mobile-action';
import DesktopNavLink from './components/DesktopNavLink';
import classes from './Header.module.scss';
import contactImage from '../../../assets/images/icon/white/phoneIcon.svg';
import LogoLink2 from 'components/link/LogoLink2/LogoLink2';

const Header = () => {
  const dispatch = useDispatch() as Dispatch<any>;
  
  const { isMobile, isScrolled } = useSelector((state: RootState) => state.client);
  const sectionData = useSelector((state: RootState) => state.section.data);
  const contactData = useSelector((state: RootState) => state.contact.data.phone_number);


  useEffect(() => {
    dispatch(clientSetMobile(window.innerWidth));
  }, [dispatch]);

  const renderLeftLinks = sectionData.map((section, index) => {
    if (index >= Math.floor(sectionData.length / 2)) {
      return null; 
    }

    return <DesktopNavLink
      key={section.id}
      title={section.title}
      route={section.route}
    />
  })

  const renderRightLinks = sectionData.map((section, index) => {
    if (index < Math.floor(sectionData.length / 2)) {
      return null; 
    }

    return <DesktopNavLink
      key={section.id}
      title={section.title}
      route={section.route}
    />
  })

  const headerClass = isScrolled
    ? `${classes['header']} ${classes['header--scrolled']}`
    : `${classes['header']}`
  return (
    <>
      <header
        className={headerClass}
      >
        <div className={classes['header__logo']}>
          <LogoLink2 route={'/'} />

          {!isMobile && 
            <div className={`${classes['header__links']} ${classes['header__links--left']}`}>
              {renderLeftLinks}
            </div>}
          {!isMobile && 
          <div className={`${classes['header__links']} ${classes['header__links--right']}`}>
            {renderRightLinks}
          </div>}
        </div>

        {!isMobile && <div className={classes['header__contact']}>
          <img src={contactImage} alt={'contact'} className={classes['header__contact-img']} />
          <span className={classes['header__contact-text']}>{ contactData }</span>
        </div>}
        
      </header>
      {isMobile && <HamburgerMenu />}
    </>
  )
}

export default Header