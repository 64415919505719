import { createSlice } from "@reduxjs/toolkit";
import { FAQ_FAIL, FAQ_REQUEST, FAQ_SUCCESS } from "data/constants/store/FAQ";
import { FAQ } from "data/interfaces/FAQ";

const faqSlice = createSlice({
  name: 'faq',
  initialState: {
    loading: false,
    error: '',
    data: [] as Array<FAQ>
  },
  reducers: {
    get(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case FAQ_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case FAQ_SUCCESS:
          state.loading = false;
          state.error = '';
          state.data = payload;
          break;
        case FAQ_FAIL:
          state.loading = false;
          state.error = payload;
          break;
      }
    },
    create(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case FAQ_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case FAQ_SUCCESS:
          state.loading = false;
          state.error = '';
          state.data.push(payload);
          break;
        case FAQ_FAIL:
          state.loading = false;
          state.error = payload;
          break;
      }
    },
    edit(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case FAQ_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case FAQ_SUCCESS:
          state.loading = false;
          state.error = '';
          state.data[payload.index] = payload.data;
          break;
        case FAQ_FAIL:
          state.loading = false;
          state.error = payload;
          break;
      }
    },
    delete(state, action) {
      const { type, payload } = action.payload;
      
      switch (type) {
        case FAQ_REQUEST:
          state.loading = true;
          state.error = '';
          break;
        case FAQ_SUCCESS:
          state.loading = false;
          state.error = '';
          state.data.splice(payload, 1);
          break;
        case FAQ_FAIL:
          state.loading = false;
          state.error = payload;
          break;
      }
    }
  }
})

export const faqActions = faqSlice.actions;
export default faqSlice;

