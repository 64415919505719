import classes from './TogleVisibilityButton.module.scss';

type Props = {
  onClick: () => void;
  isActive: boolean;
}

const TogleVisibilityButton = ({
  onClick,
  isActive
}: Props) => {
  const buttonClass = isActive 
    ? `${classes['toggle-visibility']} ${classes['toggle-visibility--active']}`
    : `${classes['toggle-visibility']}`

  return (
    <button 
      className={buttonClass}
      onClick={onClick}
      type='button'
    />
  )
}

export default TogleVisibilityButton;