import { ReactNode } from 'react';
import classes from './InputWrappr.module.scss';

type Props = {
  children: ReactNode,
  focusInput: () => void;
  isInputFocus: boolean;
  title: string;
  inputId: string;
  isValid: boolean;
  inputInfo?: Array<string>
  height?: number;
}

const InputWrapper = ({
  children, 
  focusInput,
  isInputFocus,
  title,
  inputId,
  isValid,
  inputInfo,
  height
}: Props) => {
  const setWrapperActive = () => {
    focusInput();
  }

  const inputWrapperClass = isInputFocus
    ? `${classes['input-wrapper']} ${classes['input-wrapper--active']}`
    : isValid
      ? `${classes['input-wrapper']}`
      : `${classes['input-wrapper']} ${classes['input-wrapper--invalid']}`;  

  return (
    <div className={classes.wrapper}>
      <div
        className={inputWrapperClass}
        onClick={setWrapperActive}
        style={{height: `${height ? height : 4}rem`}}
      >
        {children}
        <label 
            htmlFor={inputId}
            className={classes.title}
          >
            {title}
        </label>
      </div>
    </div>
  )
}

export default InputWrapper