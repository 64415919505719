import AdminHeader from 'components/header/AdminHeader/AdminHeader';
import SpinnerFullscreen from 'components/spinner/SpinnerFullscreen/SpinnerFullscreen';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import CreateFerry from './CreateFerry/CreateFerry';
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import classes from './Index.module.scss';
import { RootState } from 'store';
import { useEffect } from 'react';
import { getFerry } from 'store/ferry/actions/ferry-get-action';
import FerryForm from './FerryForm/FerryForm';
import { deleteFerry } from 'store/ferry/actions/ferry-delete-action';
import { editFerry } from 'store/ferry/actions/ferry-edit-action';

const Ferry = () => {
  const dispatch = useDispatch() as Dispatch<any>;

  const { loading, data } = useSelector((state: RootState) => state.ferry);

  useEffect(() => {
    dispatch(getFerry());
  }, [dispatch])

  const editFerryHandler = (
    id: string,
    title: string,
    hours: Array<string>,
    index: number) => {
    dispatch(editFerry(data, index, title, hours));
  }

  const deleteFerryHandler = (id: string, index: number) => {
    dispatch(deleteFerry(data, index));
  }

  const renderData = data.map((ferry, index)=> (
    <FerryForm
      key={index + Math.random()}
      id={ferry.id}
      title={ferry.title}
      hours={ferry.hours}
      isEditable={true}
      dispatch={editFerryHandler}
      deleteHandler={deleteFerryHandler}
      index={index}
      formTitle="Ferry"
    />
  ))

  return (
    <AdminLayout>
      <AdminHeader />
      <section className={classes['ferry-page']}>
        <CreateFerry />
        {loading && <SpinnerFullscreen isLoading={loading} />}
        {data.length <= 0 && <h1 className={classes['ferry-page__info']}>No ferry found</h1>}
        {data.length > 0 && renderData}
      </section> 
    </AdminLayout>
  )
}

export default Ferry