import { Dispatch } from "@reduxjs/toolkit"
import axios from "axios";
import { LOGO_FAIL, LOGO_REQUEST, LOGO_SUCCESS } from "data/constants/store/logo";
import { BACKEND_LOGO_ROUTE } from "data/routes/backendRoutes";
import { logoActions } from "../logo-slice";

export const editLogo = (image: File) => async (dispatch: Dispatch) => {
  dispatch(logoActions.edit({ type: LOGO_REQUEST }));

  const formData = new FormData();
  formData.append('fileToUpload', image, image.name);
  formData.append('method', 'PUT');

  const editLogoRequest = async () => {
    return await axios.post(BACKEND_LOGO_ROUTE, formData, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });
  }

  try {
    const {data} = await editLogoRequest();

    dispatch(logoActions.edit({
      type: LOGO_SUCCESS,
      payload: data.logo
    }));
  } catch (error: any) {
    dispatch(logoActions.edit({
      type: LOGO_FAIL,
      payload: error.response.data.message
    }))
  }
}