import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { SLIDER_FAIL, SLIDER_REQUEST, SLIDER_SUCCESS } from "data/constants/store/slider";
import { BACKEND_SLIDER_ROUTE } from "data/routes/backendRoutes";
import { sliderActions } from "../slider-slice";


export const getSlider = (section_id?: string) => async (dispatch: Dispatch) => {
  dispatch(sliderActions.get({ type: SLIDER_REQUEST }));

  const getSliderRequest = async () => {
    if (section_id) {
      return await axios.get(BACKEND_SLIDER_ROUTE, {params: {section_id}});
    }
    return await axios.get(BACKEND_SLIDER_ROUTE);
  }

  try {
    let { data } = await getSliderRequest();
    
    if (data.constructor !== Array) {
      data = [data];
    }
    
    dispatch(sliderActions.get({
      type: SLIDER_SUCCESS,
      payload: data
    }));
  } catch (error: any) {
    dispatch(sliderActions.get({
      type: SLIDER_FAIL,
      payload: error.response.data.message
    }))
  }
}