import { Section } from 'data/interfaces/Section';
import { backendUrl } from 'data/routes/backendRoutes';
import { Link } from 'react-router-dom';
import classes from './MainPageSection.module.scss';

interface Props extends Section {}

const MainPageSection = ({
  id,
  image_src,
  route,
  title
}: Props) => {
  return (
    <Link
      className={classes.section}
      to={route}
      style={{
        backgroundImage: `url(${backendUrl}${image_src})`,   
      }}
    >
      <h1 className={classes['section-title']}>{title}</h1>
    </Link>
  )
}

export default MainPageSection