import { Link } from 'react-router-dom';
import classes from './AdminLink.module.scss';

type Props = {
  title: string;
  route: string;
  isBackgroundImg?: boolean;
}

const AdminLink = ({
  title,
  route,
  isBackgroundImg = true
}: Props) => {
  return (
    <Link
      className={classes['admin-link']}
      to={route}
      style={{
        backgroundImage: `${!isBackgroundImg ? 'none' : ''}`
      }
      }
    >
      <h1>{title}</h1>
    </Link>
  )
}

export default AdminLink